import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useEffect, useState } from 'react';

import Card from 'components/card';
import CloseModalButton from 'components/buttons/CloseModalButton';
import { createStockExit } from 'interfaces/stockMovement';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';

const StockExitModal = (props: {
    isOpen: any;
    onClose: any;
    stock: any;
    onDone: any;
}) => {
    const { isOpen, onClose, stock, onDone } = props;
    const [inventory, setInventory] = useState<any>([]);
    const [newInventory, setNewInventory] = useState<any>([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [newMovement, setNewMovement] = useState({
        quantity: 10,
        objectId: '',
        reason: 'Vente',
        lotNumber: '',
        area: 'zone-a',
    });

    const doStockMovement = async () => {
        setButtonLoading(true);
        const data = await createStockExit({
            inventory: newInventory,
            ...newMovement,
        });
        if (data.error) {
            setButtonLoading(false);
            return console.log(data.error);
        }
        setInventory([]);
        setNewInventory([]);
        setNewMovement({
            quantity: 10,
            objectId: '',
            reason: 'Vente',
            lotNumber: '',
            area: 'zone-a',
        });
        onDone();
        onClose();
        setButtonLoading(false);
    };

    const updateInventory = (item: any, e: any) => {
        const inv = { ...inventory.find((i: any) => i._id === item._id) };

        inv.quantity = Number(e.target.value);
        setNewInventory([
            ...newInventory.filter((i: any) => i._id !== item._id),
            inv,
        ]);
    };

    const handleChange = (e: any) => {
        const { id, value } = e.target;

        if (id === 'objectId') {
            const item = stock.find((item: any) => item._id === value);
            if (item) {
                setInventory(item.inventory);
            }
        }
        setNewMovement({ ...newMovement, [id]: value });
    };

    useEffect(() => {
        if (stock && stock.length === 1) {
            setInventory(stock[0].inventory);
            setNewMovement({ ...newMovement, objectId: stock[0]._id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stock]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh] md:min-w-[650px]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004] ml-[50px] flex-auto overflow-y-auto">
                        <CloseModalButton
                            onClick={onClose}
                            extra={'w-[34px] h-[34px]'}
                        />
                        <h1 className="mb-1 text-2xl font-bold">
                            Sortie de stock
                        </h1>
                        <p className="ml-1 mb-[20px]">
                            {stock && stock.length === 1 && stock[0].name}
                        </p>
                        <div className="mb-2 mt-3 grid grid-cols-12 gap-3">
                            {stock && stock.length > 1 && (
                                <div className="col-span-12 md:col-span-4">
                                    <SearchSelector
                                        displayKey="name"
                                        secondaryKey="ref"
                                        label="Produit/Composant*"
                                        options={stock}
                                        onSelect={(item: any) =>
                                            handleChange({
                                                target: {
                                                    id: 'objectId',
                                                    value: item._id,
                                                },
                                            })
                                        }
                                    />
                                </div>
                            )}
                            <div className="col-span-6 md:col-span-6">
                                <label
                                    htmlFor="reason"
                                    className="ml-3 text-sm text-navy-700 dark:text-white"
                                >
                                    Motif*
                                </label>
                                <div className="w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                    <select
                                        id="reason"
                                        className="mb-0 w-full bg-white dark:!bg-navy-800"
                                        onChange={handleChange}
                                    >
                                        {[
                                            'Vente',
                                            'Perte',
                                            'Production',
                                            'Autre',
                                        ].map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-12 mt-5 grid h-[300px] grid-cols-12 gap-5 overflow-y-auto">
                                {[
                                    'Quantité',
                                    'DLC',
                                    'Zone',
                                    'Lot',
                                    'Quantité à sortir',
                                ].map((item, index) => (
                                    <div
                                        className={`col-span-${
                                            index === 4 ? 4 : 2
                                        }`}
                                    >
                                        <p className="text-xs font-bold md:text-sm">
                                            {item}
                                        </p>
                                    </div>
                                ))}
                                {inventory &&
                                    inventory
                                        .filter((i: any) => i.quantity !== 0)
                                        .map((item: any, index: any) => (
                                            <>
                                                {[
                                                    item.quantity,
                                                    item.dlc
                                                        ? item.dlc.split('T')[0]
                                                        : 'Aucune DLC',
                                                    item.area || 'zone-a',
                                                    item.lotNumber || 'Inconnu',
                                                ].map((item, index) => (
                                                    <div className="col-span-2 mt-4">
                                                        <p className="text-xs md:text-sm">
                                                            {item}
                                                        </p>
                                                    </div>
                                                ))}
                                                <div className="col-span-4">
                                                    <InputField
                                                        placeholder="0"
                                                        extra="col-span-3"
                                                        id="quantity"
                                                        type="number"
                                                        onChange={(e: any) =>
                                                            updateInventory(
                                                                item,
                                                                e,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ))}
                            </div>
                        </div>
                        <div className="mt-3 flex gap-2">
                            <button
                                className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={() => {
                                    doStockMovement();
                                }}
                                disabled={buttonLoading}
                            >
                                Retirer du stock
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default StockExitModal;
