import { useEffect, useState } from 'react';

import chevron from '../../../assets/svg/chevron.svg';
import {
    getDeliveryNote,
    getOrdersFolders,
    getMyAutoOrdersPerMonth,
    getMyInvoicesPerMonth,
    getAutoOrdersInvoice,
} from 'interfaces/autoOrder';
import GoBack from 'components/actions/GoBack';
import Card from 'components/card';
import Select from 'components/fields/Select';
import OrderCard from './components/OrderCard';
import InvoiceCard from './components/InvoiceCard';

const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

const AutoOrderAccount = () => {
    const [folders, setFolders] = useState<any[]>([]);
    const [orders, setOrders] = useState<any[]>([]);
    const [invoices, setInvoices] = useState<any[]>([]);
    const [page, setPage] = useState('delivery-note');

    const [year, setYear] = useState<string>('2025');
    const [month, setMonth] = useState(null);

    const initOrders = async () => {
        const res = await getMyAutoOrdersPerMonth(month, year);
        if (res) setOrders(res.data);
    };

    const initInvoices = async () => {
        const res = await getMyInvoicesPerMonth(month, year);
        if (res) setInvoices(res.data);
    };

    const initOrdersFolders = async () => {
        const res = await getOrdersFolders(year);
        if (res) setFolders(res);
    };

    const downloadDeliveryNote = async (id: string) => {
        const res = await getDeliveryNote(id);
        if (res) {
            const blob = new Blob([res], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Bon de livraison.pdf`;
            link.click();
        }
    };

    const downloadInvoice = async (
        invoiceId: string,
        invoiceNumber: string,
    ) => {
        const res = await getAutoOrdersInvoice(invoiceId);
        if (res) {
            const blob = new Blob([res], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Facture #${invoiceNumber}.pdf`;
            link.click();
        }
    };

    const Return = (props: { text?: string; onClick: any }) => {
        const { text = 'Retour', onClick } = props;
        return (
            <div
                className="flex items-center justify-start cursor-pointer"
                onClick={onClick}
            >
                <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
                <p className="text-xs">{text}</p>
            </div>
        );
    };

    const DeliveryNotesPage = () => {
        return (
            <div className="mt-2 grid grid-cols-1 lg:grid-cols-4 gap-4 px-1">
                {month &&
                    orders &&
                    orders.map((order: any, index: number) => {
                        return (
                            <OrderCard
                                orderId={order.orderId}
                                status={order.status}
                                createdAt={order.createdAt}
                                deliveredAt={order.deliveryDate}
                                products={order.items}
                                key={index}
                                downloadBL={() =>
                                    downloadDeliveryNote(order._id)
                                }
                            />
                        );
                    })}
            </div>
        );
    };

    const InvoicesPage = () => {
        return (
            <div className="mt-2 grid grid-cols-1 lg:grid-cols-4 gap-4 px-1">
                {month &&
                    invoices &&
                    invoices.map((invoice: any, index: number) => {
                        return (
                            <InvoiceCard
                                invoice={invoice}
                                key={index}
                                download={() =>
                                    downloadInvoice(
                                        invoice._id,
                                        invoice.invoiceNumber,
                                    )
                                }
                            />
                        );
                    })}
            </div>
        );
    };

    useEffect(() => {
        initOrdersFolders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    useEffect(() => {
        if (month && page === 'delivery-note') initOrders();
        if (month && page === 'invoice') initInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, year, page]);

    return (
        <div className="px-4 mt-2 h-full w-full rounded-xl bg-white mx-auto">
            <div className="mt-5 flex items-center justify-between">
                <div className="mt-2">
                    {month ? (
                        <Return
                            text="Commandes par mois"
                            onClick={() => setMonth(null)}
                        />
                    ) : (
                        <GoBack text="Retour à la boutique" />
                    )}
                </div>
                <Select
                    extra="!mt-2 pt-[5px] h-[30px] !w-[140px]"
                    options={[
                        { label: '2023', value: '2023' },
                        { label: '2024', value: '2024' },
                        { label: '2025', value: '2025' },
                    ]}
                    displayKey="label"
                    onSelect={(e: any) => setYear(e.value)}
                    required={false}
                    selected={'2025'}
                />
            </div>
            {month && (
                <div className="ml-3 mt-2 flex gap-3">
                    <p
                        className={`${
                            page === 'delivery-note' && 'underline'
                        } hover:cursor-pointer`}
                        onClick={() => setPage('delivery-note')}
                    >
                        Bons de livraison
                    </p>
                    <p
                        className={`${
                            page === 'invoice' && 'underline'
                        } hover:cursor-pointer`}
                        onClick={() => setPage('invoice')}
                    >
                        Factures
                    </p>
                </div>
            )}
            <div className="mt-2 grid grid-cols-1 lg:grid-cols-4 gap-4 px-1">
                {!month &&
                    folders.map((folder: any, index: number) => {
                        return (
                            <Card
                                extra={`flex flex-col w-full h-[150px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] hover:cursor-pointer`}
                                key={index}
                                onClick={() => {
                                    setMonth(folder.month);
                                    setPage('delivery-note');
                                }}
                            >
                                <h1 className="font-semibold">
                                    {months[folder.month - 1]} {year}
                                </h1>
                                <p className="mt-2 flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white">
                                    {folder.total} commandes:
                                </p>
                                <p className="mt-2 flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white">
                                    <img src={chevron} alt="chevron" />{' '}
                                    {folder.delivered} commandes livrées
                                </p>
                                <p className="mt-2 flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white">
                                    <img src={chevron} alt="chevron" />{' '}
                                    {folder.invoiced} factures disponibles
                                </p>
                            </Card>
                        );
                    })}
                {folders.length === 0 && (
                    <div className="w-full h-[150px] flex items-center justify-center">
                        <h1 className="text-[#A3AED0] dark:text-white">
                            Aucune commande pour cette année
                        </h1>
                    </div>
                )}
            </div>
            {page === 'delivery-note' && <DeliveryNotesPage />}
            {page === 'invoice' && <InvoicesPage />}
            {/* <button
                className="flex mx-auto mt-5 mb-5 px-10 py-1 rounded-lg bg-toola-500 text-white hover:bg-toola-600"
                onClick={getMoreOrders}
            >
                Voir plus
            </button> */}
        </div>
    );
};

export default AutoOrderAccount;
