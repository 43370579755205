import Card from 'components/card';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import ActionButton from 'components/buttons/ActionButton';
import SearchSelector from 'components/fields/SearchSelector';

const StartProductionModal = (props: {
    isOpen: any;
    onClose: any;
    onProduction: any;
    products: any;
    productName: string;
    lotNumber?: string;
}) => {
    const { isOpen, onClose, onProduction, products, lotNumber } = props;
    const [product, setProduct] = useState<any>({
        date: new Date().toISOString().split('T')[0],
        quantity: 1,
        area: 'zone-a',
        lotNumber: lotNumber,
        comment: ' ',
        product: '',
        type: '',
    });

    useEffect(() => {
        setProduct({
            ...product,
            lotNumber: lotNumber,
        });
    }, [lotNumber]);

    useEffect(() => {
        setProduct({
            ...product,
            product: products[0]._id,
        });
    }, [products]);

    const handleChange = (e: any) => {
        setProduct({ ...product, [e.target.id]: e.target.value });
    };

    const createProd = () => {
        onProduction(product);
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[45%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra={'w-full p-5 mb-3 border'}>
                            <div>
                                <h4 className="text-lg font-medium">
                                    Vos productions
                                </h4>
                                <p className="mt-2 text-base text-gray-600">
                                    Transformez et assemblez vos produits.
                                </p>
                            </div>
                            <div className="mt-4 grid grid-cols-12 gap-3">
                                <div className="col-span-6 gap-2">
                                    <SearchSelector
                                        displayKey="name"
                                        secondaryKey="ref"
                                        label="Produit*"
                                        options={products}
                                        value={products[0]._id}
                                        defaultKey="_id"
                                        onSelect={handleChange}
                                        inputSmall={true}
                                    />
                                </div>
                                <div className="col-span-4">
                                    <InputField
                                        label="Date*"
                                        placeholder="2021-09-01"
                                        value={product.date}
                                        onChange={handleChange}
                                        id="date"
                                        type="date"
                                        inputSmall={true}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <InputField
                                        label="Quantité*"
                                        placeholder="1"
                                        id="quantity"
                                        type="number"
                                        value={product.quantity}
                                        onChange={handleChange}
                                        inputSmall={true}
                                    />
                                </div>
                                <div className="col-span-6 gap-2">
                                    <label
                                        htmlFor={`zone`}
                                        className={`text-navy-700 text-xs font-medium`}
                                    >
                                        Zone*
                                    </label>
                                    <div className="flex w-full h-[35px] rounded-xl border border-gray-200 px-2.5 py-auto text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                        <select
                                            id="area"
                                            className="w-full bg-white"
                                            onChange={handleChange}
                                        >
                                            {[
                                                { v: 'zone-a', l: 'Zone A' },
                                                { v: 'zone-b', l: 'Zone B' },
                                                { v: 'zone-c', l: 'Zone C' },
                                            ].map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item.v}
                                                >
                                                    {item.l}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <InputField
                                        label="Numéro de lot*"
                                        placeholder="FO-0025"
                                        value={product.lotNumber}
                                        id="lotNumber"
                                        type="text"
                                        onChange={(e: any) => handleChange(e)}
                                        inputSmall={true}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <InputField
                                        label="Commentaire"
                                        placeholder={
                                            'Production de Lucas à ' +
                                            new Date()
                                                .toISOString()
                                                .split('T')[1]
                                                .split('.')[0]
                                        }
                                        id="comment"
                                        type="text"
                                        onChange={handleChange}
                                        inputSmall={true}
                                    />
                                </div>
                                <div className="col-span-6 mt-6">
                                    <ActionButton
                                        value="Prévisualiser"
                                        extra="text-xs py-1 3xl:text-base h-[35px] bg-toola-500 hover:bg-toola-600 active:bg-toola-700"
                                        onClick={createProd}
                                    />
                                </div>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default StartProductionModal;
