import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import { getCustomers } from 'interfaces/customer';
import {
    addBulkTransaction,
    getNotPaidInvoicedPerCustomer,
} from 'interfaces/invoice';
import { useEffect, useState } from 'react';

const defaultPaymentMethod =
    localStorage.getItem('defaultPaymentMethod-id') === null
        ? 'transfer'
        : localStorage.getItem('defaultPaymentMethod-id');
const defaultPaymentMethodValue =
    localStorage.getItem('defaultPaymentMethod-value') === null
        ? 'Virement'
        : localStorage.getItem('defaultPaymentMethod-value');
const defaultDate =
    localStorage.getItem('defaultPayementDate') === null
        ? new Date().toISOString().split('T')[0]
        : localStorage.getItem('defaultPayementDate');

console.log(`DefaultDate: ${defaultDate}`);
const EnterPayement = (props: { isOpen: any; onClose: any }) => {
    const { isOpen, onClose } = props;
    const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
    const [transaction, setTransaction] = useState({
        transactions: [],
        date: defaultDate,
        totalPayed: 0,
        paymentMethod: defaultPaymentMethodValue,
        reference: '',
    });
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(3);

    const handleChanges = (e: any) => {
        const { id, value } = e.target;
        setTransaction((prev: any) => ({ ...prev, [id]: value }));
    };

    const handleGlobalAmountChange = (e: any) => {
        const { value } = e.target;

        let totalAmount = value;
        const transacs = transaction.transactions.filter(
            (t: any) => t.leftToPay > 0,
        );
        console.log(transaction);
        for (const transac of transacs) {
            if (transac.leftToPay < totalAmount) {
                transac.amount = transac.leftToPay;
                totalAmount -= transac.leftToPay;
            } else {
                transac.amount = totalAmount;
                totalAmount = 0;
            }
            transac.amount = parseFloat(parseFloat(transac.amount).toFixed(2));
        }
        setTransaction((prev: any) => ({
            ...prev,
            transactions: transacs,
            totalPayed: value,
        }));
    };

    const initCustomers = async () => {
        const res = await getCustomers();
        if (res) setCustomers(res.data);
    };

    const initInvoices = async () => {
        if (!selectedCustomer) return;
        const res = await getNotPaidInvoicedPerCustomer(
            limit,
            page,
            selectedCustomer?._id,
        );
        if (res && res.data) {
            setInvoices(res.data.data);
            setPage(page);
            setLimit(limit);

            // Init transactions
            const transactions = res.data.data
                .filter((i: any) => i.leftToPay > 0)
                .map((invoice: any) => {
                    return {
                        invoiceId: invoice._id,
                        amount: 0,
                        leftToPay: invoice.leftToPay,
                    };
                });
            setTransaction({
                ...transaction,
                transactions,
                totalPayed: 0,
                paymentMethod: transaction.paymentMethod,
                reference: transaction.reference,
            });
        }
    };

    useEffect(() => {
        console.log(transaction);
    }, [transaction]);

    const updateInvoicePaidAmount = (e: any, invoice: any) => {
        const { value } = e.target;
        let transacs = transaction.transactions;

        const actualTransac = transacs.find(
            (transac: any) => transac.invoiceId === invoice._id,
        );
        if (actualTransac) {
            actualTransac.amount = value;
        } else {
            transacs.push({
                invoiceId: invoice._id,
                amount: value,
            });
        }
        setTransaction((prev: any) => ({ ...prev, transactions: transacs }));
    };

    const getTransValue = (invoiceId: string) => {
        const transac = transaction.transactions.find(
            (transac: any) => transac.invoiceId === invoiceId,
        );
        return transac;
    };

    useEffect(() => {
        console.log(`CHANGE DATE: ${transaction.date}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction.date]);

    const onClick = async () => {
        const res = await addBulkTransaction(transaction);
        console.log(`Sending: ${transaction.date}`);
        if (res && res.data) {
            window.location.reload();
        }
    };

    useEffect(() => {
        initInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);

    useEffect(() => {
        initCustomers();
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[5vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <>
                            <h1 className="mb-2 text-xl font-medium">
                                Pointer un paiement
                            </h1>
                            <div className="grid grid-cols-2 gap-3 mb-3">
                                <SearchSelector
                                    label="Client*"
                                    displayKey="company"
                                    value={selectedCustomer?._id}
                                    options={customers}
                                    onSelect={(e: any) => {
                                        setSelectedCustomer(e);
                                    }}
                                    inputSmall={true}
                                />
                                <InputField
                                    id="date"
                                    label="Date de paiement*"
                                    type="date"
                                    value={transaction?.date}
                                    inputSmall={true}
                                    onChange={(e: any) => {
                                        console.log(
                                            `Value changed to ${e.target.value}`,
                                        );
                                        handleChanges(e);
                                        localStorage.setItem(
                                            'defaultPayementDate',
                                            e.target.value,
                                        );
                                    }}
                                />
                                <SearchSelector
                                    label="Méthode de paiement"
                                    displayKey="name"
                                    value={paymentMethod}
                                    inputSmall={true}
                                    options={[
                                        {
                                            _id: 'cash',
                                            name: 'Espèces',
                                        },
                                        {
                                            _id: 'check',
                                            name: 'Chèque',
                                        },
                                        {
                                            _id: 'transfer',
                                            name: 'Virement',
                                        },
                                        {
                                            _id: 'credit-card',
                                            name: 'Carte bancaire',
                                        },
                                        {
                                            _id: 'direct-debit',
                                            name: 'Prélèvement',
                                        },
                                    ]}
                                    onSelect={(e: any) => {
                                        setTransaction({
                                            ...transaction,
                                            paymentMethod: e.name,
                                        });
                                        setPaymentMethod(e._id);
                                        localStorage.setItem(
                                            'defaultPaymentMethod-id',
                                            e._id,
                                        );
                                        localStorage.setItem(
                                            'defaultPaymentMethod-value',
                                            e.name,
                                        );
                                    }}
                                />
                                <InputField
                                    id="reference"
                                    label="Référence de paiement"
                                    type="text"
                                    placeholder="RF-2021-0001"
                                    inputSmall={true}
                                    value={transaction.reference}
                                    onChange={handleChanges}
                                />
                            </div>
                            <InputField
                                id="totalPayed"
                                label="Montant payé*"
                                type="number"
                                value={transaction.totalPayed}
                                inputSmall={true}
                                onChange={handleGlobalAmountChange}
                            />
                            <p className="mt-3 text-xs">
                                Le montant payé se répercute automatiquement sur
                                les trois dernières factures
                            </p>
                            <div className="mt-2 flex items-center justify-between mb-3">
                                <p className="mt-2 mb-1 text-sm">N facture</p>
                                <p className="mt-2 mb-1 text-sm">
                                    Reste à payer
                                </p>
                                <p className="mt-2 mb-1 text-sm">
                                    Montant payé
                                </p>
                            </div>
                            {invoices && invoices.length > 0 && (
                                <div className="grid grid-cols-1 gap-3">
                                    {invoices
                                        .filter((i) => i.leftToPay > 0)
                                        .reverse()
                                        .map((invoice: any, index: number) => (
                                            <div
                                                key={index}
                                                className="flex items-center justify-between p-1 bg-gray-100/50 rounded-lg"
                                            >
                                                <p className="text-sm">
                                                    #{invoice.invoiceNumber}
                                                </p>
                                                <p className="text-sm">
                                                    {invoice.leftToPay} € TTC
                                                </p>
                                                <div className="flex">
                                                    <InputField
                                                        id={invoice._id}
                                                        type="number"
                                                        extra="w-[70px] text-right"
                                                        value={
                                                            getTransValue(
                                                                invoice._id,
                                                            )
                                                                ? getTransValue(
                                                                      invoice._id,
                                                                  ).amount
                                                                : 0
                                                        }
                                                        inputSmall={true}
                                                        onChange={(e: any) =>
                                                            updateInvoicePaidAmount(
                                                                e,
                                                                invoice,
                                                            )
                                                        }
                                                    />
                                                    <p className="mx-1 mt-2.5">
                                                        €
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </>
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                className="linear hover:bg-toola-600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={onClick}
                            >
                                {'Pointer le paiement'}
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EnterPayement;
