import axios from 'axios';

import env from 'variables/config';
import { errorHandler } from './utils';

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    };
};

export const getGeneratedInvoicedQuantity = async () => {
    const res: any = await axios
        .get(`/invoices/quantity`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const generateCreditNote = async (
    invoiceNbr: string,
    amount: number,
    vat: number,
) => {
    const res: any = await axios
        .get(
            `/invoices/${invoiceNbr}/credit-note?amount=${amount}&vat=${vat}`,
            config(),
        )
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const generateCreditNoteFromScratch = async (data: any) => {
    const res: any = await axios
        .post(`/invoices/credit-note`, data, config())
        .catch((err) => {
            return null;
        });
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const generateInvoiceForSpecificOrder = async (orderId: string) => {
    const res: any = await axios
        .post(`/invoices/${orderId}`, {}, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateEmailModel = async (name: string, content: string) => {
    const res: any = await axios
        .put(`/invoices/email-model`, { name, content }, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getInvoiceEmailModel = async (invoiceId: string) => {
    const res: any = await axios
        .get(`/invoices/${invoiceId}/email-model`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const sendInvoiceEmail = async (
    invoiceId: string,
    name: string,
    content: string,
) => {
    const res: any = await axios
        .post(`/invoices/${invoiceId}/send-email`, { name, content }, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const editInvoiceStatus = async (id: string, status: string) => {
    const res: any = await axios
        .put(`/invoices/${id}`, { status }, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const addBulkTransaction = async (transactions: any) => {
    const res: any = await axios
        .post(`/invoices//transactions-bulk`, transactions, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const addTransaction = async (invoiceId: string, transaction: any) => {
    const res: any = await axios
        .post(`/invoices/${invoiceId}/transactions`, transaction, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const cancelTransaction = async (
    invoiceId: string,
    transactionId: string,
) => {
    const res: any = await axios
        .delete(
            `/invoices/${invoiceId}/transactions/${transactionId}`,
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getPaginatedInvoices = async (
    limit: number,
    page: number,
    month: number,
    year: number,
    search: string,
    status: string,
) => {
    const res: any = await axios
        .get(
            `/invoices?limit=${limit}&page=${page}&month=${month}&year=${year}&search=${search}&status=${status}`,
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getPaginatedUnpaidInvoices = async (
    limit: number,
    page: number,
    month: number,
    year: number,
) => {
    const res: any = await axios
        .get(
            `/invoices/unpaid?limit=${limit}&page=${page}&month=${month}&year=${year}`,
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getUnpaidInvoicesData = async (month: number, year: number) => {
    const res: any = await axios
        .get(`/invoices/unpaid/data?month=${month}&year=${year}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getNotPaidInvoicedPerCustomer = async (
    limit: number,
    page: number,
    customerId: string,
) => {
    const res: any = await axios
        .get(`/invoices/${customerId}?limit=${limit}&page=${page}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getInvoices = async () => {
    const res: any = await axios
        .get('/invoices?limit=100', config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getMonthlyInvoicesFolder = async (year: string) => {
    const res: any = await axios
        .get(`/invoices/${year}/months`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getPreviewInvoices = async (
    startDate: string,
    endDate: string,
    customerId: string,
    billingPeriod: string,
    invoiceNbr: string,
) => {
    const res: any = await axios
        .post(
            `/invoices/previews`,
            { startDate, endDate, customerId, invoiceNbr, billingPeriod },
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createInvoice = async (
    startDate: string,
    endDate: string,
    customerId: string,
    billingPeriod: string,
    invoiceNbr: string,
) => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .post(
            `/invoices`,
            { startDate, endDate, customerId, invoiceNbr, billingPeriod },
            fileConfig,
        )
        .catch((err) => {
            console.log(err);
            // Console log error message coming from backend
            console.log(err.response.data);
            // transform arraybuffer to text
            const data = new TextDecoder('utf-8').decode(err.response.data);
            // transform text to json
            const json = JSON.parse(data);
            errorHandler({ response: { data: { error: json.error } } });
        });
    if (!res || !res.data) return null;
    return new Blob([res.data], { type: 'application/pdf' });
};

export const downloadInvoice = async (id: string) => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .get(`/invoices/${id}/download`, fileConfig)
        .catch((err) => {
            console.log(err);
            console.log(err.response.data);
            const data = new TextDecoder('utf-8').decode(err.response.data);
            const json = JSON.parse(data);
            errorHandler({ response: { data: { error: json.error } } });
        });
    if (!res || !res.data) return null;
    return new Blob([res.data], { type: 'application/pdf' });
};

export const downloadAccountingExport = async (exportAll: string = 'false') => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .get(`/invoices/export?all=${exportAll}`, fileConfig)
        .catch((err) => {
            const data = new TextDecoder('utf-8').decode(err.response.data);
            const json = JSON.parse(data);
            errorHandler({ response: { data: { error: json.error } } });
        });
    if (!res || !res.data) return null;
    return new Blob([res.data], { type: 'text/csv' });
};

export const downloadSimpleExport = async (
    startDate: string,
    endDate: string,
    paymentMethod: string,
) => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .get(
            `/invoices/export/simple?startDate=${startDate}&endDate=${endDate}&paymentMethod=${paymentMethod}`,
            fileConfig,
        )
        .catch((err) => {
            const data = new TextDecoder('utf-8').decode(err.response.data);
            const json = JSON.parse(data);
            errorHandler({ response: { data: { error: json.error } } });
        });
    if (!res || !res.data) return null;
    return new Blob([res.data], { type: 'text/csv' });
};

export const downloadAccountingExportModel = async (
    exportModel: string,
    exportAll: string = 'false',
) => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .get(`/invoices/export/${exportModel}?all=${exportAll}`, fileConfig)
        .catch((err) => {
            const data = new TextDecoder('utf-8').decode(err.response.data);
            const json = JSON.parse(data);
            errorHandler({ response: { data: { error: json.error } } });
        });
    if (!res || !res.data) return null;
    return new Blob([res.data], { type: 'text/csv' });
};
