import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import CreateButton from 'components/buttons/CreateButton';
import Card from 'components/card';
import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { getIcon } from 'utils/icons';

const ProductViewModal = (props: {
    isOpen: any;
    onClose: any;
    product: any;
    newOrder: any;
    onAddProduct: any;
    onQuantityChange: any;
}) => {
    const {
        isOpen,
        onClose,
        product,
        newOrder,
        onAddProduct,
        onQuantityChange,
    } = props;
    const [quantity, setQuantity] = useState(null);
    const [newQuantity, setNewQuantity] = useState(1);

    const handleSubmit = async () => {
        if (!quantity) {
            onAddProduct(product?.id, product?.name, newQuantity);
        } else {
            onQuantityChange(newQuantity || 0, product?.id);
            if (newQuantity === 0) {
                setNewQuantity(1);
            }
        }
        onClose();
    };

    useEffect(() => {
        const productOrderInfo = newOrder.items.find(
            (i: any) => i.productId === product.id,
        );

        if (productOrderInfo) {
            setNewQuantity(productOrderInfo.quantity);
            setQuantity(productOrderInfo.quantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newOrder, product]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#332626] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-[95%] lg:!w-[55%] !min-h-[95%] top-2">
                <ModalBody>
                    <Card extra="relative px-[30px] pt-4 pb-[40px] flex !z-[1004]">
                        <div
                            className="absolute top-4 right-4 hover:cursor-pointer"
                            onClick={onClose}
                        >
                            <IoMdClose size={20} />
                        </div>
                        <div className="flex justify-between">
                            <h1 className="mb-2 text-lg font-bold">
                                {product?.name}
                            </h1>
                        </div>
                        <div className="mt-10 flex mx-auto">
                            <div className="mb-[2px] h-[140px] flex items-center justify-center">
                                <object
                                    data={product?.imageUrl}
                                    type="image/png"
                                    className={`max-h-[200px] border rounded-xl`}
                                >
                                    <img
                                        className={`max-h-[100px]`}
                                        src={getIcon(product?.name, 'product')}
                                        alt=""
                                    />
                                </object>
                            </div>
                        </div>
                        <p className="mt-10">Description</p>
                        <p className="mt-2 text-xs max-h-[150px] overflow-y-scroll">
                            {product?.description ||
                                'Aucune description pour le moment'}
                        </p>
                        <p className="mt-4 font-bold">
                            {product?.price}€ / {product?.unity}
                        </p>
                        <div className="flex justify-between">
                            <div className="mt-4 flex items-center gap-[6px]">
                                <input
                                    id={'0'}
                                    value={newQuantity.toString()}
                                    min={0}
                                    onChange={(e) =>
                                        setNewQuantity(
                                            e.target.value
                                                ? parseFloat(e.target.value)
                                                : 0,
                                        )
                                    }
                                    type="number"
                                    className={`noscroll flex w-[59px] h-[40px] rounded-xl border bg-white/0 px-2 text-sm outline-none`}
                                />
                                <span className="text-gray-600 text-sm">
                                    {product?.unity}
                                </span>
                            </div>
                            <div className="mt-5 my-[4px] flex gap-[10px] items-center">
                                <span className="bold text-black font-bold text-sm">
                                    Total :
                                </span>
                                <span
                                    className={`text-xl font-bold leading-6 text-[#0260CB] dark:text-white`}
                                >
                                    {(newQuantity * product?.price).toFixed(2)}
                                    {' €'}
                                </span>
                            </div>
                        </div>
                        <CreateButton
                            value={
                                !quantity
                                    ? 'Ajouter'
                                    : newQuantity > 0
                                      ? 'Modifier'
                                      : 'Supprimer'
                            }
                            extra={`mt-[5px] h-[34px] !rounded-full ${
                                !newQuantity || newQuantity === 0
                                    ? 'bg-red-400'
                                    : 'bg-[#0260CB] hover:bg-[#0260CB]'
                            }`}
                            onClick={() => {
                                if ((!quantity && newQuantity > 0) || quantity)
                                    handleSubmit();
                            }}
                        />
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ProductViewModal;
