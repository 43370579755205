import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import CloseModalButton from 'components/buttons/CloseModalButton';
import Card from 'components/card';

const PrintDeliveryModal = (props: {
    isOpen: any;
    onClose: any;
    printDelivery: any;
    printBLDelivery: any;
}) => {
    const { isOpen, onClose, printDelivery, printBLDelivery } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[450px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[950px] flex flex-col !z-[1004]">
                        <CloseModalButton
                            onClick={onClose}
                            extra={'w-[34px] h-[34px]'}
                        />
                        <h1 className="mb-[20px] text-xl font-bold">
                            Récapitulatif de la tournée
                        </h1>
                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 text-center">
                            <div>
                                <p className="text-base font-medium">
                                    Feuille de route
                                </p>
                                <p className="text-xs font-medium">Incluant:</p>
                                <p className="text-sx mt-3">
                                    ✅ Adresse de livraison
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Numéro de téléphone client
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Contact du client
                                </p>
                                <p className="mt-2 text-sm">
                                    ❌ Prix unitaires et totaux
                                </p>
                                <div className="mt-5 flex justify-center">
                                    <button
                                        className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-sm font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={() => printDelivery(false)}
                                    >
                                        Télécharger la feuille de route
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p className="text-base font-medium">
                                    Bilan de la tournée
                                </p>
                                <p className="text-xs font-medium">Incluant:</p>
                                <p className="text-sm mt-3">
                                    ✅ Adresse de livraison
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Numéro de téléphone client
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Contact du client
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Prix unitaires et totaux
                                </p>
                                <div className="mt-5 flex justify-center">
                                    <button
                                        className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-sm font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={() => printDelivery(true)}
                                    >
                                        Télécharger le bilan de la tournée
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p className="text-base font-medium">
                                    Bilan de la tournée
                                </p>
                                <p className="text-xs font-medium">
                                    Page par page incluant:
                                </p>
                                <p className="text-sm mt-3">
                                    ✅ Adresse de livraison
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Numéro de téléphone client
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Contact du client
                                </p>
                                <p className="mt-2 text-sm">
                                    ❌ Prix unitaires et totaux
                                </p>
                                <div className="mt-5 flex justify-center">
                                    <button
                                        className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-sm font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={() => printBLDelivery(false)}
                                    >
                                        Télécharger le bilan de la tournée
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p className="text-base font-medium">
                                    Bilan de la tournée
                                </p>
                                <p className="text-xs font-medium">
                                    Page par page incluant:
                                </p>
                                <p className="text-sm mt-3">
                                    ✅ Adresse de livraison
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Numéro de téléphone client
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Contact du client
                                </p>
                                <p className="mt-2 text-sm">
                                    ✅ Prix unitaires et totaux
                                </p>
                                <div className="mt-5 flex justify-center">
                                    <button
                                        className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-sm font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={() => printBLDelivery(true)}
                                    >
                                        Télécharger le bilan de la tournée
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default PrintDeliveryModal;
