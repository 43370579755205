import { useDisclosure } from '@chakra-ui/hooks';
import DeleteModal from 'components/actions/Delete';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import {
    archiveCustomer,
    getCustomer,
    updateCustomer,
} from 'interfaces/customer';
import { getDeliveryTours } from 'interfaces/deliveryTour';
import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
    isFormErrored,
    isValidEmail,
    isValidPhoneNumber,
    isValidSiren,
    isValidVATNumber,
} from 'utils/verification';
import SaveStatus from 'components/actions/SaveStatus';
import GoBack from 'components/actions/GoBack';
import env from 'variables/config';
import axios from 'axios';
import InputTagField from 'components/fields/InputTagField';
import { getCompanyAccounts, updateMyInfo } from 'interfaces/user';
import Switch from 'components/switch';

const EditInformation = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [customer, setCustomer] = useState<any>(null);
    const [deliveryTours, setDeliveryTours] = useState<any>([]);
    const [isBillingAddress, setIsBillingAddress] = useState(false);
    const [saveStatus, setSaveStatus] = useState<any>('none');
    const [user, setUser] = useState(null);
    const [accounts, setAccounts] = useState([]);

    const initUser = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${env.apiUrl}/user/me`, config)
            .then((res: any) => {
                const user = res.data;
                setUser(user);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const addNewTag = async (newTag: string) => {
        if (!user) return;
        await updateMyInfo({ tags: [...user.tags, newTag] });
    };

    const handleChange = (e: any) => {
        setSaveStatus('saving');
        setCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const handleChangeBilling = (e: any) => {
        if (isBillingAddress) {
            handleChange(e);
            return;
        }
        setCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            billingAddress: e.target.value,
            deliveryAddress: e.target.value,
        }));
    };

    const initCustomer = async (id: string) => {
        const res = await getCustomer(id);
        if (res) {
            if (res.billingAddress) setIsBillingAddress(true);
            setCustomer(res);
            return;
        }
        navigate('/customers');
    };
    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(false);
        if (res) {
            setDeliveryTours(res.data);
            return;
        }
    };

    const initAccounts = async () => {
        const res = await getCompanyAccounts();
        if (res) {
            setAccounts(res.data);
        }
    };

    const editCustomer = async () => {
        const validationRules: any = {
            mail: isValidEmail,
            billingEmail: isValidEmail,
            phone: (value: any) => !value || isValidPhoneNumber(value),
            vatNumber: (value: any) => !value || isValidVATNumber(value),
            siren: (value: any) => !value || isValidSiren(value),
            company: (value: any) => !!value.trim(),
        };

        if (isFormErrored(customer, validationRules)) {
            setSaveStatus('error');
            return;
        }

        const data = {
            _id: customer._id,
            deliveryTourId: customer.deliveryTourId,
            deliveryAddress: customer.deliveryAddress,
            billingAddress: customer.billingAddress,
            billingEmail: customer.billingEmail,
            secondBillingEmail: customer.secondBillingEmail,
            billingPeriod: customer.billingPeriod,
            contact: customer.contact,
            company: customer.company,
            customerId: customer.customerId,
            mail: customer.mail,
            notes: customer.notes,
            vatNumber: customer.vatNumber,
            siren: customer.siren,
            phone: customer.phone,
            paymentMethod: customer.paymentMethod,
            displayName: customer.displayName,
            tags: customer.tags,
            assignedTo: customer.assignedTo,
            sendDeliveryEmail: customer.sendDeliveryEmail,
        };
        const res = await updateCustomer(data);
        if (res.data) setSaveStatus('saved');
        else setSaveStatus('error');
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            editCustomer();
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initCustomer(params.id);
        initDeliveryTours();
        initUser();
        initAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-1 col-span-12">
                <GoBack text="Fiche client" />
            </div>
            <div className="mt-2 grid grid-cols-12 gap-3">
                <DeleteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    description="Êtes-vous sûr de vouloir archiver ce client ?"
                    context="un client"
                    archived={true}
                    deleteFunction={() => {
                        archiveCustomer(params.id);
                        navigate('/customers');
                    }}
                />
                <div className={`col-span-12`}>
                    <Card extra={'w-full !p-5 border'}>
                        {/* Header */}
                        <div className="w-full px-[8px]">
                            <div className="grid grid-cols-4 gap-2">
                                <h4 className="col-span-1 sm:col-span-2 text-lg font-medium text-navy-700 dark:text-white">
                                    {customer
                                        ? customer.company
                                        : 'Edition client'}
                                </h4>
                                <div className="col-span-2 col-start-10">
                                    <button
                                        className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-2 py-2 text-xs text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                        onClick={() => onOpen()}
                                    >
                                        Archiver{' '}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* inputs */}
                        <div className="grid gap-3 grid-cols-12">
                            <div className="mt-2 flex col-span-12 justify-between">
                                <p className="text-base">Informations client</p>
                                <SaveStatus status={saveStatus} />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label="Numéro client"
                                    placeholder="00012"
                                    value={customer?.customerId}
                                    id="customerId"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Nom commercial"
                                    placeholder="Nom commercial"
                                    value={
                                        customer?.displayName
                                            ? customer?.displayName
                                            : ''
                                    }
                                    id="displayName"
                                    type="text"
                                    onChange={handleChange}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Raison sociale*"
                                    placeholder="Toola"
                                    value={customer?.company}
                                    id="company"
                                    type="text"
                                    onChange={handleChange}
                                    error="Raison sociale invalide"
                                    required={true}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-4">
                                <InputField
                                    label="Email (Boutique B2B)*"
                                    placeholder="mathieu@toola.eu"
                                    error={'Champ requis'}
                                    isInvalid={!isValidEmail(customer?.mail)}
                                    value={customer?.mail || ''}
                                    id="mail"
                                    type="email"
                                    onChange={handleChange}
                                    required={true}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label="Téléphone"
                                    placeholder="Téléphone"
                                    error={'invalide'}
                                    isInvalid={
                                        !isValidPhoneNumber(customer?.phone)
                                    }
                                    value={customer?.phone}
                                    id="phone"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label={`Contact`}
                                    placeholder="Contact"
                                    value={customer?.contact}
                                    id="contact"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-8 grid">
                                <InputField
                                    label={`Notes utiles`}
                                    placeholder="Notes utiles"
                                    value={customer?.notes}
                                    id="notes"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                                <InputTagField
                                    label="Tags clients"
                                    placeholder=""
                                    id="tags"
                                    type="text"
                                    extra=""
                                    tags={customer?.tags}
                                    allTags={
                                        user
                                            ? user.tags.map((name: string) => ({
                                                  name,
                                              }))
                                            : []
                                    }
                                    onTagCreate={(tag: any) => addNewTag(tag)}
                                    onChange={(tags: any) =>
                                        handleChange({
                                            target: { id: 'tags', value: tags },
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <SearchSelector
                                    label="Commercial assigné"
                                    displayKey="fullName"
                                    options={accounts}
                                    value={
                                        customer?.assignedTo.length > 0
                                            ? customer.assignedTo[0]
                                            : null
                                    }
                                    onSelect={(e: any) => {
                                        setSaveStatus('saving');
                                        handleChange({
                                            target: {
                                                id: 'assignedTo',
                                                value: [e._id],
                                            },
                                        });
                                    }}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        <div className="mt-5 grid gap-3 grid-cols-12">
                            <p className="col-span-12 text-base">
                                Informations de livraison
                            </p>
                            <div className="col-span-12 lg:col-span-6">
                                <InputField
                                    label="Adresse de livraison*"
                                    placeholder={`Champ de Mars, 5 Av. Anatole France, 75007 Paris, France`}
                                    value={customer?.deliveryAddress || ''}
                                    id="deliveryAddress"
                                    type="text"
                                    onChange={handleChangeBilling}
                                    required={true}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <SearchSelector
                                    label="Tournée de livraison"
                                    displayKey="name"
                                    options={deliveryTours}
                                    value={customer?.deliveryTourId}
                                    onSelect={(e: any) => {
                                        setSaveStatus('saving');
                                        setCustomer((prevCustomer: any) => ({
                                            ...prevCustomer,
                                            deliveryTourId: e._id,
                                        }));
                                    }}
                                    canCreate={true}
                                    createNew={() =>
                                        window.open(
                                            '/settings/deliveries',
                                            '_blank',
                                        )
                                    }
                                    inputSmall={true}
                                />
                            </div>
                            <div className="mt-3 lg:mt-0 col-span-12 lg:col-span-3">
                                <label
                                    htmlFor={'sendDeliveryEmail'}
                                    className={`text-xs font-medium text-navy-700 dark:text-white`}
                                >
                                    Envoyer le BL à la livraison
                                </label>
                                <div className="mt-2 ml-2 flex">
                                    <Switch
                                        onChange={() => {
                                            setSaveStatus('saving');
                                            setCustomer(
                                                (prevCustomer: any) => ({
                                                    ...prevCustomer,
                                                    sendDeliveryEmail:
                                                        !prevCustomer.sendDeliveryEmail,
                                                }),
                                            );
                                        }}
                                        id={'sendDeliveryEmail'}
                                        isDefaultChecked={
                                            customer?.sendDeliveryEmail
                                        }
                                        color="toola"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 grid gap-3 grid-cols-12">
                            <p className="col-span-12 text-base">
                                Informations de facturation
                            </p>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Email de facturation*"
                                    placeholder="mathieu@toola.eu"
                                    error={'Email invalide'}
                                    isInvalid={
                                        !isValidEmail(customer?.billingEmail)
                                    }
                                    value={customer?.billingEmail || ''}
                                    id="billingEmail"
                                    type="email"
                                    onChange={handleChange}
                                    required={true}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Email de facturation secondaire"
                                    placeholder="mathieu@toola.eu"
                                    error={'Email invalide'}
                                    isInvalid={
                                        !isValidEmail(customer?.billingEmail)
                                    }
                                    value={customer?.secondBillingEmail || ''}
                                    id="secondBillingEmail"
                                    type="email"
                                    onChange={handleChange}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <SearchSelector
                                    label="Période de facturation*"
                                    displayKey="name"
                                    value={customer?.billingPeriod}
                                    inputSmall={true}
                                    options={[
                                        {
                                            _id: 'flexible',
                                            name: 'flexible',
                                        },
                                        {
                                            _id: 'delivery',
                                            name: 'à la livraison',
                                        },
                                        {
                                            _id: '10-days',
                                            name: '10 jours',
                                        },
                                        {
                                            _id: '15-days',
                                            name: '15 jours',
                                        },
                                        { _id: '1-month', name: '1 mois' },
                                    ]}
                                    onSelect={(e: any) => {
                                        setSaveStatus('saving');
                                        setCustomer((prevCustomer: any) => ({
                                            ...prevCustomer,
                                            billingPeriod: e._id,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label={`Numéro de SIREN`}
                                    placeholder="123568941"
                                    value={customer?.siren}
                                    id="siren"
                                    type="text"
                                    onChange={handleChange}
                                    isInvalid={!isValidSiren(customer?.siren)}
                                    error={'SIREN invalide'}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="mt-4 col-span-12 lg:col-span-6 lg:mt-0">
                                <InputField
                                    label={`Adresse de facturation*`}
                                    placeholder="Champ de Mars, 5 Av. Anatole France, 75007 Paris, France"
                                    id="billingAddress"
                                    type="text"
                                    value={customer?.billingAddress || ''}
                                    onChange={handleChange}
                                    required={true}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <SearchSelector
                                    label="Moyen de paiement*"
                                    displayKey="name"
                                    value={customer?.paymentMethod}
                                    inputSmall={true}
                                    options={[
                                        {
                                            _id: 'cash',
                                            name: 'Espèces',
                                        },
                                        {
                                            _id: 'check',
                                            name: 'Chèque',
                                        },
                                        {
                                            _id: 'transfer',
                                            name: 'Virement',
                                        },
                                        {
                                            _id: 'credit-card',
                                            name: 'Carte bancaire',
                                        },
                                        {
                                            _id: 'direct-debit',
                                            name: 'Prélèvement',
                                        },
                                    ].filter(
                                        (e) =>
                                            !user ||
                                            user?.acceptedPaymentMethods?.includes(
                                                e._id,
                                            ),
                                    )}
                                    onSelect={(e: any) => {
                                        setSaveStatus('saving');
                                        setCustomer((prevCustomer: any) => ({
                                            ...prevCustomer,
                                            paymentMethod: e._id,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label={`Numéro de TVA`}
                                    placeholder="FR32123456789"
                                    value={customer?.vatNumber}
                                    id="vatNumber"
                                    type="text"
                                    onChange={handleChange}
                                    isInvalid={
                                        !isValidVATNumber(customer?.vatNumber)
                                    }
                                    error={'Numéro de TVA invalide'}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        {saveStatus === 'saved' && (
                            <div className="mt-5">
                                <GoBack text="Retour à la fiche client" />
                            </div>
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default EditInformation;
