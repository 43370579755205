import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import CreateButton from 'components/buttons/CreateButton';
import { getNotInvoicedOrders } from 'interfaces/order';
import {
    createInvoice,
    getGeneratedInvoicedQuantity,
} from 'interfaces/invoice';
import Card from 'components/card';
import InvoiceOrderCard from './component/invoiceOrderCard';
import SearchSelector from 'components/fields/SearchSelector';
import { getCustomersWithNotInvoicedOrder } from 'interfaces/customer';
import InputField from 'components/fields/InputField';
import Pagination from 'components/pagination/Pagination';
import InvoicePreviewModal from './component/invoicePreview';
import { useDisclosure } from '@chakra-ui/hooks';
import { bi } from '@fullcalendar/core/internal-common';

const GenerateInvoice = () => {
    const nav = useNavigate();
    const [orders, setOrders] = useState(null);
    const [ordersMeta, setOrdersMeta] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [generationLoading, setGenerationLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [billingPeriod, setBillingPeriod] = useState('1-month'); // ["all", "delivery", "10-days", "15-days", "1-month"]
    const [startDate, setStartDate] = useState(null);
    const [invoiceNbr, setInvoiceNbr] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(8);

    const fetchOrders = async (
        page: number,
        limit: number,
        startDate: string,
        endDate: string,
        billingPeriod: string,
        selectedCustomer: string | null,
    ) => {
        const res = await getNotInvoicedOrders(
            page,
            limit,
            startDate,
            endDate,
            billingPeriod,
            selectedCustomer,
        );
        if (res) {
            setOrders(res.data);
            setOrdersMeta(res.meta);
        }
    };

    const initInvoiceNbr = async () => {
        const res = await getGeneratedInvoicedQuantity();
        const month = new Date().getMonth() + 1;
        const monthStr = month < 10 ? `0${month}` : month;

        if (res)
            setInvoiceNbr(
                `${new Date().getFullYear()}-${monthStr}-${
                    res.invoiceNumber + 1
                }`,
            );
    };

    const initCustomers = async () => {
        const res = await getCustomersWithNotInvoicedOrder();
        if (res) {
            setCustomers(res);
        }
    };

    const handleGenerateInvoice = async () => {
        setGenerationLoading(true);
        try {
            const blob = await createInvoice(
                startDate,
                endDate,
                selectedCustomer,
                billingPeriod,
                invoiceNbr,
            );
            if (!blob) {
                setGenerationLoading(false);
                return;
            }
            nav('/invoices');
            setGenerationLoading(false);
        } catch (error) {
            console.error('Error generating PDF:', error);
            setGenerationLoading(false);
        }
    };

    const getOrdersContext = () => {
        if (billingPeriod === 'all') return 'tout vos clients';
        if (billingPeriod === '10-days')
            return 'vos clients facturés à 10 jours';
        if (billingPeriod === 'delivery')
            return 'vos clients facturés à la livraison';
        if (billingPeriod === '15-days')
            return 'vos clients facturés à 15 jours';
        if (billingPeriod === '1-month') return 'vos clients facturés au mois';
        if (selectedCustomer) {
            const customer = customers.find(
                (c: any) => c.id === selectedCustomer,
            );
            return customer ? customer.company : 'ce client';
        }
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        initDatesToLastMonth();
        initInvoiceNbr();
        initCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!startDate || !endDate) return;
        fetchOrders(
            page,
            limit,
            startDate,
            endDate,
            billingPeriod,
            selectedCustomer,
        );
    }, [page, limit, startDate, endDate, billingPeriod, selectedCustomer]);

    const DiscoverCommandPage = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit bg-blue-200 shadow shadow-3xl'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Transformez vos commandes en factures 🧾
                    </p>
                    <p className="mt-3 mb-3 text-base text-gray-600">
                        Il {ordersMeta && !ordersMeta.total && 'ne'} reste{' '}
                        <b className="text-gray-700">
                            {ordersMeta && ordersMeta.total
                                ? ordersMeta.total
                                : 'aucune'}{' '}
                            commande
                            {ordersMeta && ordersMeta.total > 1 ? 's ' : ' '}
                        </b>
                        non-facturées pour {getOrdersContext()}.
                    </p>
                </div>
            </Card>
        );
    };

    const getDayXDaysAgo = (days: number) => {
        const date = new Date();
        date.setDate(date.getDate() - days);
        return date.toISOString().split('T')[0];
    };

    const initDatesToLastMonth = () => {
        const firstDay = new Date();
        const lastDay = new Date();

        firstDay.setMonth(firstDay.getMonth() - 1);
        firstDay.setDate(1);
        lastDay.setMonth(lastDay.getMonth());
        lastDay.setDate(0);
        setStartDate(firstDay.toISOString().split('T')[0]);
        setEndDate(lastDay.toISOString().split('T')[0]);
    };

    const init15DaysBillingPeriod = () => {
        const date = new Date();
        if (date.getDate() <= 15) {
            date.setDate(16);
            date.setMonth(date.getMonth() - 1);
            setStartDate(date.toISOString().split('T')[0]);
            date.setMonth(date.getMonth() + 1);
            date.setDate(0);
            setEndDate(date.toISOString().split('T')[0]);
        } else {
            date.setDate(1);
            setStartDate(date.toISOString().split('T')[0]);
            date.setDate(15);
            setEndDate(date.toISOString().split('T')[0]);
        }
    };

    const init10DaysBillingPeriod = () => {
        const date = new Date();
        if (date.getDate() >= 10 && date.getDate() < 20) {
            date.setDate(1);
            setStartDate(date.toISOString().split('T')[0]);
            date.setDate(10);
            setEndDate(date.toISOString().split('T')[0]);
        } else if (date.getDate() >= 20) {
            date.setDate(11);
            setStartDate(date.toISOString().split('T')[0]);
            date.setDate(20);
            setEndDate(date.toISOString().split('T')[0]);
        } else {
            date.setDate(21);
            date.setMonth(date.getMonth() - 1);
            setStartDate(date.toISOString().split('T')[0]);
            date.setMonth(date.getMonth() + 1);
            date.setDate(0);
            setEndDate(date.toISOString().split('T')[0]);
        }
    };

    const onSelect = (e: any) => {
        if (e.value === '1-month' || e.value === 'delivery') {
            initDatesToLastMonth();
        } else if (e.value === '15-days') {
            init15DaysBillingPeriod();
        } else if (e.value === '10-days') {
            init10DaysBillingPeriod();
        } else {
            setStartDate(getDayXDaysAgo(60));
            setEndDate(new Date().toISOString().split('T')[0]);
        }
        if (
            e.value &&
            e.value.includes([
                'delivery',
                '10-days',
                '15-days',
                '1-month',
                'all',
            ])
        )
            setSelectedCustomer(null);
        else setSelectedCustomer(e.id);

        setBillingPeriod(e.value);
        setPage(1);
    };

    return (
        <>
            <InvoicePreviewModal
                isOpen={isOpen}
                onClose={onClose}
                invoiceContext={{
                    startDate,
                    endDate,
                    selectedCustomer,
                    billingPeriod,
                    invoiceNbr,
                }}
                generateInvoices={() => {
                    !generationLoading && handleGenerateInvoice();
                }}
            />
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                <div className="col-span-12">
                    <DiscoverCommandPage />
                </div>
                <div className="col-span-12 h-full w-full rounded-[20px]">
                    <>
                        <div className="ml-2 mt-2 grid grid-cols-12 gap-3">
                            <div
                                className={`z-40 col-span-12 sm:col-span-6 lg:col-span-3 ${
                                    isOpen && 'hidden'
                                }`}
                            >
                                <SearchSelector
                                    label="Facturer les commandes de"
                                    displayKey="company"
                                    value={
                                        selectedCustomer
                                            ? selectedCustomer
                                            : billingPeriod
                                    }
                                    options={[
                                        {
                                            _id: '10-days',
                                            value: '10-days',
                                            company:
                                                'Clients facturés à 10 jours',
                                        },
                                        {
                                            _id: '15-days',
                                            value: '15-days',
                                            company:
                                                'Clients facturés à 15 jours',
                                        },
                                        {
                                            _id: '1-month',
                                            value: '1-month',
                                            company: 'Clients facturés au mois',
                                        },
                                        {
                                            _id: 'delivery',
                                            value: 'delivery',
                                            company:
                                                'Clients facturés à la livraison',
                                        },
                                        {
                                            _id: 'all',
                                            value: 'all',
                                            company: 'Tous mes clients',
                                        },
                                        ...customers,
                                    ]}
                                    onSelect={onSelect}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-6 lg:col-span-2">
                                <InputField
                                    label="Du"
                                    id="startDate"
                                    type="date"
                                    value={startDate && startDate.split('T')[0]}
                                    onChange={(e: any) =>
                                        setStartDate(e.target.value)
                                    }
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-6 lg:col-span-2">
                                <InputField
                                    label="Jusqu'au"
                                    id="endDate"
                                    type="date"
                                    value={endDate && endDate.split('T')[0]}
                                    onChange={(e: any) => {
                                        setEndDate(e.target.value);
                                    }}
                                />
                            </div>
                            {!billingPeriod && (
                                <div className="col-span-12 sm:col-span-6 lg:col-span-2">
                                    <InputField
                                        label="N° Facture"
                                        id="number"
                                        value={invoiceNbr}
                                        disabled={true}
                                        type="text"
                                        onChange={(e: any) =>
                                            setInvoiceNbr(e.target.value)
                                        }
                                    />
                                </div>
                            )}
                            <div className="ml-3 mt-5 col-span-12 sm:col-span-6 lg:col-span-3">
                                <CreateButton
                                    value={
                                        generationLoading
                                            ? 'Génération en cours...'
                                            : 'Générer la facture'
                                    }
                                    extra={`mt-3 my-auto h-10 ${
                                        generationLoading &&
                                        'cursor-not-allowed bg-toola-500 opacity-50'
                                    }`}
                                    onClick={onOpen}
                                />
                            </div>
                        </div>
                    </>
                    {billingPeriod && billingPeriod !== 'all' && (
                        <div className="col-span-12 ml-3 mt-1 align-center items-center">
                            <p className="text-sm">
                                Affichage des commandes clients avec une période
                                de facturation
                                {billingPeriod === '10-days'
                                    ? ' de 10 jours'
                                    : billingPeriod === '15-days'
                                      ? ' de 15 jours'
                                      : billingPeriod === '1-month'
                                        ? " d'un mois"
                                        : billingPeriod === 'delivery'
                                          ? ' à la livraison'
                                          : 'de 60 jours'}
                                .
                            </p>
                        </div>
                    )}
                    {ordersMeta && ordersMeta.total > 0 && (
                        <p className="ml-5 mt-4">
                            Vous allez facturer {ordersMeta.total} commandes{' '}
                        </p>
                    )}
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                        {orders && orders.length === 0 && (
                            <div className="col-span-12 ml-2 align-center items-center">
                                <p className="text-xl">
                                    Aucune commande non-facturée avec ces
                                    critères.
                                </p>
                            </div>
                        )}
                        {orders &&
                            orders.map((order: any, index: number) => {
                                return (
                                    <InvoiceOrderCard
                                        key={index}
                                        id={order._id}
                                        company={order.customer.company}
                                        orderId={order.orderId}
                                        createdAt={order.createdAt}
                                        deliveryDate={order.deliveryDate}
                                        items={order.items}
                                        totalPrice={order.totalPrice}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
            {/* pagination */}
            {ordersMeta && ordersMeta.total > 0 && (
                <Pagination
                    extra="mt-4"
                    text={true}
                    type="commandes"
                    maxItems={ordersMeta ? ordersMeta.total : 12}
                    itemsPerPage={[8, 16, 24, 32, 40]}
                    page={page}
                    onChange={(page: number, limit: number) => {
                        setPage(page);
                        setLimit(limit);
                    }}
                />
            )}
        </>
    );
};

export default GenerateInvoice;
