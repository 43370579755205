import { MdOutlineSyncAlt, MdPerson } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

const Header = (props: { companyInfos: any; haveMultipleSupliers: any }) => {
    const { companyInfos, haveMultipleSupliers } = props;
    const params = useParams();

    const nav = useNavigate();

    return (
        <div className="flex bg-white rounded-b-xl">
            {companyInfos && companyInfos.logo && (
                <img
                    src={companyInfos.logo}
                    alt="logo"
                    className="ml-2 max-w-[200px] max-h-[60px] py-2"
                />
            )}
            {companyInfos && companyInfos.contact && (
                <div className="ml-6 mt-2 text-[11px] max-w-[150px] sm:max-w-none text-navy-700">
                    <p className="font-bold">Contact:</p>
                    <p>{companyInfos && companyInfos.contact}</p>
                </div>
            )}
            {haveMultipleSupliers && params.v && (
                <div className="absolute right-[55px] xl:right-[80px] top-5 border rounded-xl p-1 hover:cursor-pointer hover:bg-gray-100">
                    <MdOutlineSyncAlt
                        size={20}
                        onClick={() => nav(`/portal/${params.v}`)}
                    />
                </div>
            )}
            <div className="absolute right-5 xl:right-10 top-5 border rounded-xl p-1 hover:cursor-pointer hover:bg-gray-100">
                <MdPerson size={20} onClick={() => nav('/command/account')} />
            </div>
        </div>
    );
};

export default Header;
