import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIcon } from 'utils/icons';
function StockCard(props: {
    name: string;
    img: string;
    unity: string;
    quantity: number;
    type: string;
    id: string;
    alert: number;
    reference?: string;
    productType?: string;
    color?: string;
}) {
    const navigate = useNavigate();
    const [displayQR, setDisplayQR] = useState(false);
    const {
        name,
        img,
        unity,
        quantity,
        type,
        id,
        alert,
        reference,
        color,
        productType,
    } = props;
    return (
        <div
            className={`relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none `}
            onClick={() => {
                navigate(
                    `${
                        type === 'product'
                            ? '/update-product'
                            : '/update-ingredient'
                    }/${id}`,
                );
            }}
            onMouseOver={() => setDisplayQR(true)}
            onMouseLeave={() => setDisplayQR(false)}
        >
            {color && (
                <div
                    className={`absolute  top-3 left-3 h-4 w-4 bg-opacity-80 rounded-full border`}
                    style={{ backgroundColor: color }}
                ></div>
            )}
            <p className="text-xs absolute bottom-2 left-4 text-gray-600">
                {productType === 'transformed' && 'Transformé'}
                {productType === 'assembled' && 'Assemblé'}
                {productType === 'brut' && 'Brut'}
            </p>

            <div className="grid grid-cols-12">
                <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                    <object
                        data={type === 'product' ? img : getIcon(name, type)}
                        type="image/png"
                        className={`h-[80px] w-[80px] rounded-xl`}
                    >
                        <img
                            className={`h-[80px] w-[80px] p-2 rounded-xl ${
                                displayQR && 'mb-2'
                            } `}
                            src={getIcon(name, type)}
                            alt=""
                        />
                    </object>
                </div>
                <div className="col-span-8 ml-4">
                    <h2
                        className={`text-md font-medium leading-6 text-navy-700 dark:text-white`}
                    >
                        {name}
                    </h2>
                    <p className="mb-1 text-xs font-medium text-gray-600">
                        {reference ? reference : 'Référence: inconnue'}
                    </p>
                    <p className=" mb-1 text-sm font-medium text-gray-600">
                        {type === 'product' ? 'Seuil:' : 'Seuil:'}{' '}
                        {quantity <= alert && (
                            <>
                                <span className="text-red-500">alerte </span>
                                <span className="text-red-500">!</span>
                            </>
                        )}
                        {quantity > alert && (
                            <span className="text-green-500">OK</span>
                        )}
                    </p>
                    <h3
                        className={`text-xl font-bold leading-6 text-navy-700 dark:text-white`}
                    >
                        {quantity % 1 === 0 ? quantity : quantity.toFixed(2)}{' '}
                        {unity}
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default StockCard;
