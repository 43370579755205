import Card from 'components/card';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { createProduction, getSuggestion } from 'interfaces/productions';
import { useNavigate } from 'react-router-dom';

const ProductionPreviewModal = (props: {
    isOpen: any;
    onClose: any;
    onProduction: any;
    product: any;
    productName: string;
}) => {
    const { isOpen, onClose, product, productName, onProduction } = props;
    const [suggestions, setSuggestions] = useState<any>([]);
    const navigate = useNavigate();

    const getFifoSuggestions = async () => {
        const res = await getSuggestion({
            product: product.product,
            quantity: product.quantity,
        });
        if (!res) return;
        // Adding production form data
        res.date = product.date;
        res.comment = product.comment;
        res.area = product.area;
        res.lotNumber = product.lotNumber;
        setSuggestions(res);
    };

    const handleChange = (ingId: any, moveId: any, value: any) => {
        const newSuggestions = Object.assign({}, suggestions);
        const ingIndex = newSuggestions.ingredients.findIndex(
            (ing: any) => ing.id === ingId,
        );
        const movIndex = newSuggestions.ingredients[
            ingIndex
        ].movements.findIndex((mov: any) => mov.id === moveId);
        if (value === '')
            newSuggestions.ingredients[ingIndex].movements[movIndex].quantity =
                '';
        else
            newSuggestions.ingredients[ingIndex].movements[movIndex].quantity =
                Number(value);
        setSuggestions(newSuggestions);
        console.log(suggestions);
    };

    useEffect(() => {
        if (product && product.product && product.product !== '' && isOpen) {
            getFifoSuggestions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, isOpen]);

    const startProduction = async () => {
        const res = await createProduction(suggestions);
        if (res.error) return;
        onProduction(res.data);
    };

    const isProductionPossible = () => {
        if (suggestions && suggestions.ingredients) {
            for (let i = 0; i < suggestions.ingredients.length; i++) {
                const ing = suggestions.ingredients[i];
                if (ing.quantity < ing.totalNeeded) return false;
            }
            return true;
        }
        return false;
    };

    const getSuggest = () => {
        if (suggestions && suggestions.ingredients)
            return suggestions.ingredients;
        return [];
    };

    const isProdToday = () => {
        const today = new Date();
        const prodDate = new Date(product.date);
        return (
            today.getDate() === prodDate.getDate() &&
            today.getMonth() === prodDate.getMonth() &&
            today.getFullYear() === prodDate.getFullYear()
        );
    };

    const getMissingProductsUrl = () => {
        const missingProducts = getSuggest().filter(
            (ing: any) => ing.quantity < ing.totalNeeded,
        );
        if (missingProducts.length === 0) return '';
        let url = 'missingP=';
        missingProducts.forEach((ing: any) => {
            url += `${ing.name},`;
        });
        return url.slice(0, -1);
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                            <h1 className="text-lg font-medium">
                                Prévisualisation
                            </h1>
                            <div className="mt-5 grid h-[200px] grid-cols-2 gap-3 overflow-y-auto xl:h-[350px]">
                                <p className="col-span-6">{productName}</p>
                                <p className="mx-3 font-bold text-green-500">
                                    {'+'} {product.quantity}
                                </p>
                                {getSuggest().length === 0 ? (
                                    <div className="col-span-12 text-center text-lg font-medium text-gray-800">
                                        {
                                            'Aucun ingrédient nécessaire pour cette production.'
                                        }
                                    </div>
                                ) : (
                                    <div className="col-span-12 h-[1px] w-full bg-gray-200" />
                                )}
                                {getSuggest().map((ing: any, index: number) => {
                                    // Sum of all ingredients movements quantity
                                    const sum = ing.movements.reduce(
                                        (a: any, b: any) =>
                                            a + (b.quantity || 0),
                                        0,
                                    );
                                    return (
                                        <Accordion
                                            className="col-span-12 w-full"
                                            allowMultiple
                                            key={index}
                                        >
                                            <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                                                <h2>
                                                    <AccordionButton className="grid justify-items-end">
                                                        <span className="flex text-left text-navy-900 dark:text-white">
                                                            {ing.name}
                                                        </span>
                                                        <span className="ml-[45%] mr-1 text-right font-bold text-red-400 dark:text-white">
                                                            {'-'} {sum}{' '}
                                                            {ing.unity}
                                                        </span>
                                                        <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                                                    </AccordionButton>
                                                    <span
                                                        className={`flex text-left text-xs ${
                                                            ing.quantity <
                                                            ing.totalNeeded
                                                                ? 'text-red-400'
                                                                : 'text-navy-900'
                                                        } dark:text-white`}
                                                    >
                                                        Vous avez{' '}
                                                        {ing.quantity.toFixed(
                                                            2,
                                                        )}{' '}
                                                        {ing.unity} en stock
                                                        {ing.quantity <
                                                        ing.totalNeeded
                                                            ? `, la recette initiale nécessite ${ing.totalNeeded.toFixed(
                                                                  2,
                                                              )} ${ing.unity}.`
                                                            : '.'}
                                                    </span>
                                                    {ing.expiredStockQuantity >
                                                        0 && (
                                                        <span
                                                            className={`my-1 flex text-left text-xs font-bold text-red-400`}
                                                        >
                                                            Cette production
                                                            contient{' '}
                                                            {ing.expiredStockQuantity.toFixed(
                                                                2,
                                                            )}{' '}
                                                            {ing.unity} avec une
                                                            DLC dépassé.
                                                        </span>
                                                    )}
                                                </h2>
                                                <AccordionPanel
                                                    className="text-medium mt-2 text-left !text-navy-900 dark:!text-white"
                                                    pb={4}
                                                >
                                                    <div className="col-span-12 mb-2 mt-3 grid grid-cols-2 gap-3">
                                                        <div
                                                            key={index}
                                                            className="col-span-12 text-sm font-medium text-gray-600"
                                                        >
                                                            {`Zone,  DLC,  Lot N°, Quantité disponible`}
                                                        </div>
                                                        {ing.movements.map(
                                                            (
                                                                mov: any,
                                                                index: number,
                                                            ) => (
                                                                <div
                                                                    key={index}
                                                                    className="col-span-12"
                                                                >
                                                                    <div className="flex items-center justify-between">
                                                                        <p
                                                                            className={`col-span-10 text-justify text-sm font-medium text-gray-600`}
                                                                        >
                                                                            {`${
                                                                                mov.area
                                                                            }, ${
                                                                                mov.dlc
                                                                                    ? mov.dlc.split(
                                                                                          'T',
                                                                                      )[0]
                                                                                    : 'Aucune DLC'
                                                                            }, ${
                                                                                mov.lotNumber
                                                                            }, ${mov.stockQuantity.toFixed(
                                                                                2,
                                                                            )} ${
                                                                                ing.unity
                                                                            }`}
                                                                        </p>
                                                                        <InputField
                                                                            placeholder={mov.quantity.toString()}
                                                                            id="quantity"
                                                                            type="number"
                                                                            extra={`col-span-2 ml-5 text-sm font-medium w-[75px] text-gray-400`}
                                                                            onChange={(
                                                                                e: any,
                                                                            ) =>
                                                                                handleChange(
                                                                                    ing.id,
                                                                                    mov.id,
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    );
                                })}
                            </div>
                            <div className="mt-3 flex gap-2">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Retour
                                </button>
                                {!isProductionPossible() && isProdToday() && (
                                    <div className="flex gap-2">
                                        <button
                                            className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-xs font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                            onClick={() =>
                                                window.open(
                                                    `/stock/movements?entry=true&${getMissingProductsUrl()}`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Rajouter du stock
                                        </button>
                                        <button
                                            className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-xs font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                            onClick={() =>
                                                window.open(
                                                    `/stock/production`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Lancer une autre production
                                        </button>
                                    </div>
                                )}
                                {isProductionPossible() && isProdToday() && (
                                    <button
                                        className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={() => startProduction()}
                                    >
                                        Lancer la production
                                    </button>
                                )}
                                {!isProdToday() && (
                                    <button
                                        className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={() => startProduction()}
                                    >
                                        Programmer la production
                                    </button>
                                )}
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ProductionPreviewModal;
