import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { calculateTotalPurchasePrice } from 'utils/margin';

const SubProductList = (props: {
    product: any;
    handleQtyChange?: any;
    handleDiscountTypeChange?: any;
    handleDiscountChange?: any;
    handleCommentChange?: any;
    onDelete: any;
}) => {
    const { product, handleQtyChange, onDelete } = props;

    const [newProduct, setNewProduct] = useState<any>(product);

    useEffect(() => {
        setNewProduct(product);
    }, [product]);

    return (
        <table className="w-full">
            <thead className="hidden sm:contents">
                <tr key={0} className="!border-px !border-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Produit
                        </p>
                    </th>
                    <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Quantité
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Prix d'achat unitaire
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Coût dans la recette
                        </p>
                    </th>
                    <th
                        key={4}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Actions
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {newProduct.subProducts.map((item: any, index: number) => (
                    <tr key={`${item.name}-${index}`}>
                        <td
                            key={`${item.name}-${index}-product`}
                            className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <div>
                                <div className="flex gap-[5px] items-center">
                                    <p className="text-sm font-medium text-navy-700 dark:text-white xl:leading-3">
                                        {item.name}
                                    </p>
                                    <GoLinkExternal
                                        className="cursor-pointer hover:text-gray-500"
                                        onClick={() => {
                                            window.open(
                                                `/update-product/${item._id}`,
                                                '_blank',
                                            );
                                        }}
                                    />
                                </div>
                                <p className="font-base mt-[2px] text-gray-600">
                                    {item.ref}
                                </p>
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-quantity`}
                            className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <div className="flex gap-[5px] items-center">
                                <InputField
                                    key={`${item.name}-${index}-qty`}
                                    extra="w-[70px]"
                                    placeholder="Quantité"
                                    id={item.name + '_qty'}
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e: any) => {
                                        handleQtyChange(
                                            item._id,
                                            e.target.value,
                                        );
                                    }}
                                    disabled={false}
                                    inputSmall={true}
                                />
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {item.unity}
                                </p>
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-mobile`}
                            className="sm:hidden border-b text-sm dark:border-white/10 py-[10px] w-full"
                        >
                            <div>
                                <div className="flex gap-[5px] items-center">
                                    <p className="text-sm font-medium text-navy-700 dark:text-white xl:leading-3">
                                        {item.name}
                                    </p>
                                </div>
                                <p className="font-base text-gray-600">
                                    {item.ref}
                                </p>
                                <div className="flex gap-[5px] items-center">
                                    <p className="text-sm font-medium text-navy-700 dark:text-white">
                                        Quantité :
                                    </p>
                                    <InputField
                                        key={`${item.name}-${index}-qty-m`}
                                        extra="w-[50px]"
                                        inputSmall={true}
                                        placeholder="Quantité"
                                        id={item.name + '_qty_m'}
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e: any) => {
                                            handleQtyChange(
                                                item._id,
                                                e.target.value,
                                            );
                                        }}
                                    />
                                    {item.unity}
                                </div>
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-purchasePrice`}
                            className="border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <p className="text-sm font-medium text-navy-700 dark:text-white">
                                {calculateTotalPurchasePrice(item)} €
                            </p>
                        </td>
                        <td
                            key={`${item.name}-${index}-purchasePrice`}
                            className="border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <p className="text-sm font-medium text-navy-700 dark:text-white">
                                {Number(
                                    calculateTotalPurchasePrice(item) *
                                        item.quantity,
                                ).toFixed(2)}
                                {' €'}
                            </p>
                        </td>
                        <td
                            key={`${item.name}-${index}-actions`}
                            className="border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <button
                                className="linear col-span-3 flex items-center justify-center rounded-xl bg-red-400 px-3 py-3 text-sm font-medium text-white hover:bg-red-500 active:bg-red-600"
                                onClick={() => onDelete(item._id)}
                            >
                                Retirer
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SubProductList;
