import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { verifyLoginOrder } from 'interfaces/autoOrder';
import axios from 'axios';

const MultiPortal = () => {
    const params = useParams();
    const [suppliers, setSuppliers] = useState([]);
    const [supplierContact, setSupplierContact] = useState('');
    const [customerToken, setCustomerToken] = useState(null);
    const [requestSupplier, setRequestSupplier] = useState(false);

    const sendSupplierRequest = async () => {
        const res = await axios.post(
            '/auto-orders/request-supplier',
            {
                supplierContact,
            },
            {
                headers: {
                    Authorization: `Bearer ${customerToken}`,
                },
            },
        );
        if (res) setRequestSupplier(true);
    };

    const verifyAuth = async (v: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        const supplier = urlParams.get('supplier');

        const res = await verifyLoginOrder({ magicLink: v, supplier });
        if (res) {
            setCustomerToken(res.token);
            if (supplier) {
                const supplierUrl = res.suppliers.find(
                    (s: any) => s._id === supplier,
                );
                if (supplierUrl) {
                    window.location.href = supplierUrl.url;
                    return;
                }
            }

            if (res.suppliers.length === 1) {
                window.location.href = res.suppliers[0].url;
                return;
            }
            setSuppliers(res.suppliers);
        } else {
            window.location.href = `/command/${params.v}`;
        }
    };

    useEffect(() => {
        if (params.v) verifyAuth(params.v);
        else window.location.href = `/command/${params.v}`;
    }, []);

    return (
        <div className="px-3">
            {/* Display supplier */}
            {suppliers.length > 1 && (
                <>
                    <div className="max-w-md mx-auto mt-10 bg-white border rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold text-gray-800 px-6 py-4 border-b">
                            Fournisseurs
                        </h2>
                        <ul className="divide-y divide-gray-200">
                            {suppliers.map((portal) => (
                                <li key={portal.company}>
                                    <a
                                        href={portal.url}
                                        rel="noopener noreferrer"
                                        className="flex items-center justify-between px-6 py-4 hover:bg-gray-100 transition duration-200"
                                    >
                                        <span className="text-gray-800 font-medium">
                                            {portal.company}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            className="w-5 h-5 text-gray-500"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 5l7 7-7 7"
                                            />
                                        </svg>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="max-w-md mx-auto mt-8 bg-white border rounded-lg shadow-md px-6">
                        <h2 className="text-md font-semibold text-gray-800 py-4 border-b">
                            Vous ne trouvez pas votre fournisseur ?
                        </h2>
                        {requestSupplier === false ? (
                            <>
                                <p className="text-sm mt-2 text-gray-600 mb-4">
                                    Ajoutez ces coordonnées pour l'ajouter
                                </p>
                                <div className="mb-4">
                                    <label
                                        htmlFor="supplierContact"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Contact fournisseur
                                    </label>
                                    <input
                                        type="text"
                                        id="supplierContact"
                                        name="supplierContact"
                                        onChange={(e) =>
                                            setSupplierContact(e.target.value)
                                        }
                                        placeholder="Numéro de téléphone ou email"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                </div>
                                <button
                                    onClick={sendSupplierRequest}
                                    className="text-xs mb-3 w-full bg-toola-500 text-white font-medium py-2 px-4 rounded-md hover:bg-toola-500/80 transition duration-200"
                                >
                                    Ajouter le fournisseur
                                </button>
                            </>
                        ) : (
                            <div className="text-center">
                                <p className="text-sm mt-2 text-gray-600 mb-4">
                                    Merci pour votre demande, nous allons
                                    contacter votre fournisseur
                                </p>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default MultiPortal;
