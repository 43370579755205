import { useState } from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';

import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { getIcon } from 'utils/icons';

const AddProductsModal = (props: {
    isOpen: any;
    onClose: any;
    addProducts: any;
    products: any;
    existingProducts: [string];
}) => {
    const { isOpen, onClose, addProducts, existingProducts, products } = props;

    const [search, setSearch] = useState('');
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

    const handleItemClicked = (product: any) => {
        if (!selectedProducts.includes(product))
            setSelectedProducts((prev) => [...prev, product]);
        else
            setSelectedProducts((prev) =>
                prev.filter((item) => item !== product),
            );
    };

    const searchProduct = (customer: any, search: string) => {
        for (const key in customer)
            if (
                customer[key] &&
                typeof customer[key] === 'string' &&
                customer[key]
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
            )
                return true;
        return false;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] mx-auto md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[50%] max-h-[500px] flexs !z-[1004] w-full mx-auto overflow-y-auto">
                        <h1 className="text-2xl font-bold">
                            Ajouter des produits
                        </h1>
                        <p className="mt-2 text-base text-gray-600">
                            Cliquez sur les produits dont vous souhaitez
                            personnaliser le prix.
                        </p>
                        <div className="mt-2 flex h-[35px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600">
                            <SearchIcon />
                            <input
                                type="text"
                                placeholder="Rechercher un produit, une référence, ..."
                                onChange={(e) => setSearch(e.target.value)}
                                className="mtblock w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none"
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3">
                            {products &&
                                products
                                    .filter((i: any) =>
                                        searchProduct(i, search),
                                    )
                                    .filter(
                                        (i: any) =>
                                            !existingProducts.includes(i._id),
                                    )
                                    .map((item: any, index: number) => (
                                        <div
                                            className={`relative mb-2 mt-4 flex items-center gap-3 rounded-[20px] ${
                                                selectedProducts.includes(item)
                                                    ? 'bg-green-100'
                                                    : 'bg-gray-100'
                                            } bg-clip-border py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
                                            onClick={() =>
                                                handleItemClicked(item)
                                            }
                                            key={index}
                                        >
                                            <div className="grid grid-cols-12">
                                                <div className="-bottom-12 col-span-3 flex mx-4 h-[45px] w-[45px] items-center justify-center">
                                                    <img
                                                        className="h-full w-full"
                                                        src={getIcon(
                                                            item.name,
                                                            'ingredient',
                                                        )}
                                                        alt="composant-icon"
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <h2 className="ml-3 text-xs leading-6 text-navy-700 dark:text-white">
                                                        {item.name || 'Inconnu'}
                                                    </h2>
                                                </div>
                                                <div className="absolute left-[80%]">
                                                    {selectedProducts.includes(
                                                        item,
                                                    ) ? (
                                                        <MdRemoveCircleOutline
                                                            size="25"
                                                            color="red"
                                                            onClick={() => {}}
                                                        />
                                                    ) : (
                                                        <MdAddCircleOutline
                                                            size="25"
                                                            color="green"
                                                            onClick={() => {}}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                        <div className="mt-5 flex gap-2">
                            <button
                                className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] bg-[] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#0260CB] sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={() => {
                                    addProducts(selectedProducts);
                                    setSelectedProducts([]);
                                }}
                            >
                                Personnaliser les prix
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddProductsModal;
