import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { useNavigate } from 'react-router-dom';

const CommandPageInfo = (props: { user: any; handleChange: any }) => {
    const { user, handleChange } = props;
    const nav = useNavigate();
    return (
        <Card extra={'w-full mt-3 px-6 py-6 border'}>
            {/* Header */}
            <div className="w-full px-1">
                <h4 className="text-xl font-medium">Informations générales</h4>
            </div>
            {/* inputs */}
            <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                <div className="col-span-2">
                    <InputField
                        label="Informations de contact"
                        value={user?.contactInformation}
                        placeholder={
                            '+33 6 12 34 56 78 - contact@fournisseur.fr'
                        }
                        id="contactInformation"
                        type="text"
                        onChange={handleChange}
                        inputSmall={true}
                    />
                </div>
                <div className="col-span-2">
                    <InputField
                        label="Annonces (mise en avant via un pop-up)"
                        placeholder={'Aucune livraison possible le 25 décembre'}
                        value={user?.announcements}
                        id="announcements"
                        type="text"
                        onChange={handleChange}
                        inputSmall={true}
                    />
                </div>
                {/* Card and button to handle my product visible or not per default */}
                <div
                    className="mt-1 col-span-2 bg-toola-500/20 shadow-sm rounded-lg p-2 hover:border hover:cursor-pointer"
                    onClick={() => nav('/b2b-shop/display')}
                >
                    <h4 className="text-sm font-medium">
                        Gérer la visibilité de mes produits
                    </h4>
                    <p className="mt-1 text-xs">
                        Vous pouvez choisir de cacher ou non vos produits par
                        défaut
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default CommandPageInfo;
