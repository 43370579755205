import Card from 'components/card';
import StockCard from 'components/card/StockCard';
import SearchIcon from 'components/icons/SearchIcon';
import ingredientsImg from 'assets/img/ingredients.png';
import mobilityImg from 'assets/img/mobility.png';
import { getStock } from 'interfaces/stock';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdPallet } from 'react-icons/md';
import MovementTable from './components/MovementTable';
import CreateButton from 'components/buttons/CreateButton';
import { getStockMovement } from 'interfaces/stockMovement';
import { useDisclosure } from '@chakra-ui/hooks';
import StockEntryModal from './components/StockEntryModal';
import StockExitModal from './components/StockExitModal';
import OnBoardingCard from 'views/admin/main/account/coursePage/components/OnBoardingCard';
import { useShepherdTour } from 'react-shepherd';
import SearchSelector from 'components/fields/SearchSelector';
import { productColorList } from 'utils/lib';
import { cleanStr } from 'utils/tools';

const steps: any = [
    {
        title: '3/5 Créer vos clients',
        text: 'Rendez-vous dans la section Client pour importer vos clients',
        attachTo: {
            element: '.step-customers',
            on: 'left',
        },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Importer mes clients',
                action: function (this: any): any {
                    localStorage.setItem('onboarding-step', '3');
                    window.location.href = '/customers';
                },
            },
        ],
        id: 'step-customers',
    },
];

const StockList = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [stock, setStock] = useState(null);
    const [stockMovement, setStockMovement] = useState([]);
    const [toggleState, setToggleState] = useState(1);
    const [onboarding, setOnboarding] = useState(false);
    const [category, setCategory] = useState('all');
    const [color, setColor] = useState('all');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenExit,
        onOpen: onOpenExit,
        onClose: onCloseExit,
    } = useDisclosure();
    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
    };
    const tour = useShepherdTour({ tourOptions, steps });

    const toggleTab = (index: number) => {
        setToggleState(index);
    };

    const initStock = async () => {
        const res = await getStock();
        if (res.error) {
            navigate('/auth/login');
            return;
        }
        setStock(res.data);
    };

    const initStockMovement = async () => {
        const res = await getStockMovement();
        if (res.error) {
            navigate('/auth/login');
            return;
        }
        setStockMovement(res.data.data);
    };

    useEffect(() => {
        const onboardingStep = localStorage.getItem('onboarding-step');
        const queryParameters = new URLSearchParams(window.location.search);
        const page = queryParameters.get('page');
        if (page && page === 'stock') {
            setToggleState(5);
            onOpen();
        }
        initStock();
        initStockMovement();
        if (onboardingStep === '2') setOnboarding(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SkelettonProduct = () => {
        return (
            <div
                className={`animate-pulse relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
            >
                <div className="grid grid-cols-12">
                    <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                        <div className="h-[80px] w-[80px] rounded-xl bg-gray-200"></div>
                    </div>
                    <div className="col-span-8 ml-4">
                        <div className="mt-2 w-[99%] h-4 bg-navy-700/50 rounded-full"></div>
                        <div className="my-1 w-[60px] h-2 bg-gray-600 rounded-full"></div>
                        <p className=" mb-1 text-sm font-medium text-gray-600">
                            <div className="w-5 h-3 bg-gray-600 rounded-full"></div>
                        </p>
                        <div className="w-[60px] h-5 bg-gray-600  rounded-full">
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const StockMovement = () => {
        return (
            <div className="col-span-12">
                {stockMovement && stockMovement.length > 0 && (
                    <MovementTable tableData={stockMovement} />
                )}
                {stockMovement && stockMovement.length === 0 && (
                    <div className="col-span-12 mt-10">
                        <img
                            className="mx-auto flex h-[120px] w-[120px] items-center justify-center"
                            src={mobilityImg}
                            alt=""
                        />
                        <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                            <button
                                className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                onClick={onOpen}
                            >
                                Entrer du stock
                            </button>
                            <button
                                className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-11"
                                onClick={onOpenExit}
                            >
                                Sortir du stock
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const filterStockPerZone: any = (stock: any, areaIndex: number) => {
        const areas = ['', '', 'zone-a', 'zone-b', 'zone-c', ''];
        if (areas[areaIndex] === '') return stock;
        let newStock = JSON.parse(JSON.stringify(stock));
        newStock = newStock.map((item: any) => {
            let quantity = 0;
            item.inventory.forEach((inventory: any) => {
                if (inventory.area === areas[areaIndex]) {
                    quantity += inventory.quantity;
                }
            });
            item.quantity = quantity;
            return item;
        });
        if (areaIndex > 1)
            return newStock.filter((item: any) => item.quantity > 0);
        return newStock;
    };

    const refreshData = async () => {
        initStockMovement();
        initStock();
    };

    return (
        <>
            <StockEntryModal
                onClose={onClose}
                isOpen={isOpen}
                stock={stock}
                onDone={refreshData}
            />
            <StockExitModal
                onClose={onCloseExit}
                isOpen={isOpenExit}
                stock={stock}
                onDone={refreshData}
            />
            {onboarding && (
                <div className="mt-5">
                    <OnBoardingCard
                        title="Créer vos produits"
                        actualStep={2}
                        totalStep={5}
                        description="Vous pouvez créer tout vos produits ici avant de passer à l'étape suivante"
                        onClick={() => tour.start()}
                    />
                </div>
            )}
            <Card extra={'w-full h-full bg-white mt-5'}>
                <Card extra={'w-full h-full sm:overflow-auto px-6'}>
                    {/* Tabs */}
                    <div className="h-full w-full flex-col items-center px-4 lg:px-3">
                        <div className="mt-2 flex w-full justify-between gap-0 sm:gap-0 md:gap-2 xl:gap-14 overflow-hidden border-b border-gray-200 text-3xl lg:gap-[50px] 2xl:justify-center 2xl:gap-[70px]">
                            <div
                                className={
                                    toggleState === 1
                                        ? ' flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400'
                                        : 'flex items-center gap-2 pb-[20px] hover:cursor-pointer'
                                }
                                onClick={() => toggleTab(1)}
                            >
                                <p
                                    className={`text-sm md:text-lg text-[#0260CB] dark:text-white`}
                                >
                                    <MdPallet />
                                </p>
                                <p className="text-xs md:text-[18px] font-medium text-navy-700 dark:text-white">
                                    Toutes les zones
                                </p>
                            </div>

                            <div
                                className={
                                    toggleState === 2
                                        ? ' flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400'
                                        : 'flex items-center gap-2 pb-[20px] hover:cursor-pointer'
                                }
                                onClick={() => toggleTab(2)}
                            >
                                <p className="text-sm md:text-lg text-navy-700 dark:text-white">
                                    <MdPallet />
                                </p>
                                <p className="text-xs md:text-[18px] font-medium text-navy-700 dark:text-white">
                                    Zone A
                                </p>
                            </div>

                            <div
                                className={
                                    toggleState === 3
                                        ? ' flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400'
                                        : 'flex items-center gap-2 pb-[20px] hover:cursor-pointer'
                                }
                                onClick={() => toggleTab(3)}
                            >
                                <p className="text-sm md:text-lg text-navy-700 dark:text-white">
                                    <MdPallet />
                                </p>
                                <p className="text-xs md:text-[18px] font-medium text-navy-700 dark:text-white">
                                    Zone B
                                </p>
                            </div>

                            <div
                                className={
                                    toggleState === 4
                                        ? ' flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400'
                                        : 'flex items-center gap-2 pb-[20px] hover:cursor-pointer'
                                }
                                onClick={() => toggleTab(4)}
                            >
                                <p className="text-sm md:text-lg text-navy-700 dark:text-white">
                                    <MdPallet />
                                </p>
                                <p className="text-xs md:text-[18px] font-medium text-navy-700 dark:text-white">
                                    Zone C
                                </p>
                            </div>

                            {/* <div
                                className={
                                    toggleState === 5
                                        ? " flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400"
                                        : "flex items-center gap-2 pb-[20px] hover:cursor-pointer"
                                }
                                onClick={() => toggleTab(5)}
                            >
                                <p className="text-lg text-navy-700 dark:text-white">
                                    <MdOutlineMoveDown />
                                </p>
                                <p className="text-sm md:text-lg md:text-[18px] font-medium text-navy-700 dark:text-white">
                                    Mouvements
                                </p>
                            </div> */}
                        </div>
                    </div>
                    {/* Search bar */}
                    <div className="grid max-w-full grid-cols-12 items-center gap-3 rounded-xl pt-5">
                        <div className="col-span-12 flex h-[38px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4">
                            <SearchIcon />
                            <input
                                type="text"
                                placeholder="Recherche...."
                                onChange={(e) => setSearch(e.target.value)}
                                className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                            />
                        </div>
                        {/* onClick={() => navigate("/create-product")} */}
                        {stock && stock.length > 0 && toggleState !== 5 && (
                            <>
                                <CreateButton
                                    value="Créer un produit"
                                    extra="col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                                    onClick={() => navigate('/create-product')}
                                />
                                <button
                                    className="col-span-6 rounded-xl border-2 border-[#0260CB] py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 md:col-span-3 lg:col-span-2"
                                    onClick={() => navigate('/stock/movements')}
                                >
                                    Gérer le stock
                                </button>
                            </>
                        )}
                        {toggleState === 5 &&
                            stock &&
                            stock.length > 0 &&
                            stockMovement.length > 0 && (
                                <>
                                    <button
                                        className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                        onClick={onOpen}
                                    >
                                        Entrer du stock
                                    </button>
                                    <button
                                        className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-11"
                                        onClick={onOpenExit}
                                    >
                                        Sortir du stock
                                    </button>
                                </>
                            )}
                    </div>
                    <div className="flex justify-between">
                        <div className="mt-4 flex gap-2 overflow-x-auto h-7">
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'all' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('all')}
                            >
                                Tout
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'brut' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('brut')}
                            >
                                Produit brut
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'transformed' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('transformed')}
                            >
                                Produit transformé
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'assembled' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('assembled')}
                            >
                                Produit assemblé
                            </p>
                        </div>
                        <div className="pt-3 z-50 w-[180px] text-sm">
                            <SearchSelector
                                displayKey="name"
                                options={[
                                    {
                                        name: 'Toutes les couleurs',
                                        id: 'all',
                                        _id: 'all',
                                    },
                                    ...productColorList.filter(
                                        (e: any) =>
                                            stock &&
                                            stock.some(
                                                (s: any) =>
                                                    s.colorCode === e.id,
                                            ),
                                    ),
                                ]}
                                value={color}
                                onSelect={(e: any) => {
                                    setColor(e.id);
                                }}
                                defaultKey="id"
                                canCreate={false}
                                createNew={onOpen}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    {/* Skelleton Loader */}
                    {!stock && (
                        <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16,
                            ].map((item, index) => (
                                <SkelettonProduct key={index} />
                            ))}
                        </div>
                    )}
                    {/* Items list */}
                    <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        {stock &&
                            toggleState < 5 &&
                            filterStockPerZone(stock, toggleState)
                                .filter(
                                    (e: any) =>
                                        cleanStr(e.name).includes(
                                            cleanStr(search),
                                        ) ||
                                        cleanStr(e.ref).includes(
                                            cleanStr(search),
                                        ),
                                )
                                .filter((e: any) =>
                                    category === 'all'
                                        ? true
                                        : e.productType === category,
                                )
                                .filter((e: any) =>
                                    color === 'all'
                                        ? true
                                        : e.colorCode === color,
                                )
                                .reverse()
                                .map((item: any, index: number) => (
                                    <StockCard
                                        id={item._id}
                                        key={index}
                                        name={item.name}
                                        type={item.type}
                                        alert={item.alert}
                                        img={item.imageUrl}
                                        productType={item.productType}
                                        unity={item.unity}
                                        quantity={item.quantity}
                                        reference={item.ref}
                                        color={item.colorCode}
                                    />
                                ))}

                        {stock &&
                            toggleState < 5 &&
                            filterStockPerZone(stock, toggleState).length ===
                                0 &&
                            stock.length !== 0 && (
                                <div className="col-span-12">
                                    <img
                                        className="mx-auto flex h-[120px] w-[120px] items-center justify-center"
                                        src={mobilityImg}
                                        alt=""
                                    />
                                    <p className="mt-3 text-center">
                                        Déplacez du stock de zone en zone en
                                        faisant une entrée-sortie.
                                    </p>
                                    <p className="mt-3 text-center">
                                        Vous pourrez bientôt le faire ici en 2
                                        cliques !
                                    </p>
                                    <div className="font-large flex items-center justify-center rounded-xl bg-white py-2 text-base text-gray-600 dark:!bg-navy-900 dark:text-white">
                                        <button
                                            className="linear mt-5 flex items-center justify-center rounded-xl bg-toola-500 px-10 py-2 text-base font-medium text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                            onClick={() => setToggleState(5)}
                                        >
                                            Mouvement de stock
                                        </button>
                                    </div>
                                </div>
                            )}
                        {stock && stock.length === 0 && toggleState !== 5 && (
                            <div className="col-span-12">
                                <img
                                    className="mx-auto flex h-[120px] w-[120px] items-center justify-center"
                                    src={ingredientsImg}
                                    alt=""
                                />
                                <div className="font-large flex items-center justify-center rounded-xl bg-white py-2 text-base text-gray-600 dark:!bg-navy-900 dark:text-white">
                                    <button
                                        className="linear mt-5 flex items-center justify-center rounded-xl bg-[#0260CB] px-10 py-2 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                        onClick={() =>
                                            navigate('/create-product')
                                        }
                                    >
                                        Créer votre premier produit
                                    </button>
                                </div>
                            </div>
                        )}
                        {toggleState === 5 && <StockMovement />}
                    </div>
                </Card>
            </Card>
        </>
    );
};

export default StockList;
