import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BackArrowButton from 'components/buttons/BackArrowButton';
import {
    getDelivery,
    startDelivery,
    updateDeliveryOrder,
    endDelivery,
} from 'interfaces/delivery';
import OrderCard from './components/orderCard';
import SignDelivery from './components/SignDelivery';
import DesktopRecap from './components/desktopRecap';
import PrintDeliveryModal from './components/printDeliverymodal';
import { useDisclosure } from '@chakra-ui/hooks';

const EditDeliveries = (props: { isExternalPage: boolean }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [delivery, setDelivery] = useState(null);
    const [orderToSign, setOrderToSign] = useState(null);
    const [toggleState, setToggleState] = useState('pending');
    const [showTurnSummary, setShowTurnSummary] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isExternalPage } = props;

    const initOrders = async (deliveryId: string) => {
        const res = await getDelivery(deliveryId, isExternalPage);
        if (res) setDelivery(res);
    };

    const changeToogleState = (state: string) => {
        setToggleState(state);
    };

    const changeDeliveryStatus = async (finished: boolean = false) => {
        if (finished) {
            const res = endDelivery(delivery._id, isExternalPage);
            if (res) {
                navigate(
                    '/delivery' + (isExternalPage ? '-ext' : '/delivery-list'),
                );
            }
        } else {
            const res = startDelivery(delivery._id, isExternalPage);
            if (res) {
                setDelivery({ ...delivery, startedAt: new Date() });
            }
        }
    };

    const switchOrders = (order: any, from: any, to: any) => {
        let selectedOrder = from.find(
            (delivery: any) => delivery._id === order._id,
        );
        if (from === delivery.pendingOrders) {
            selectedOrder = { ...selectedOrder, status: 'delivered' };
        } else {
            selectedOrder = { ...selectedOrder, status: 'pending' };
        }
        const newFromOrders = from.filter(
            (delivery: any) => delivery._id !== order._id,
        );
        const newToOrders = [...to, selectedOrder];
        if (from === delivery.pendingOrders) {
            setDelivery({
                ...delivery,
                pendingOrders: newFromOrders,
                deliveredOrders: newToOrders,
            });
        } else {
            setDelivery({
                ...delivery,
                deliveredOrders: newFromOrders,
                pendingOrders: newToOrders,
            });
        }
    };

    const updateOrderStatus = async (order: any) => {
        const status = order.status === 'pending' ? 'delivered' : 'pending';
        const res = await updateDeliveryOrder(
            order._id,
            delivery._id,
            { status },
            isExternalPage,
        );

        if (res) {
            if (order.status === 'pending') {
                switchOrders(
                    order,
                    delivery.pendingOrders,
                    delivery.deliveredOrders,
                );
            } else {
                switchOrders(
                    order,
                    delivery.deliveredOrders,
                    delivery.pendingOrders,
                );
            }
        }
    };

    const moveCard = async (order: any, direction: number) => {
        const prevOrder = order.deliveryOrder;
        const newOrder = order.deliveryOrder + direction;
        const orderIdToSwap = delivery.pendingOrders.find(
            (order: any) => order.deliveryOrder === newOrder,
        )._id;
        const res = await updateDeliveryOrder(
            order._id,
            delivery._id,
            {
                prevOrder,
                newOrder,
                orderIdToSwap,
            },
            isExternalPage,
        );
        if (res) {
            const newOrders = delivery.pendingOrders.map((order: any) => {
                if (order.deliveryOrder === prevOrder)
                    return { ...order, deliveryOrder: newOrder };
                else if (order.deliveryOrder === newOrder)
                    return { ...order, deliveryOrder: prevOrder };
                return order;
            });
            console.log(newOrders);
            setDelivery({ ...delivery, pendingOrders: newOrders });
        }
    };

    useEffect(() => {
        if (
            localStorage.getItem(
                (isExternalPage ? 'delivery-' : '') + 'token',
            ) === null
        ) {
            navigate('/login');
        }
        initOrders(params.deliveryId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PrintDeliveryModal
                isOpen={isOpen}
                onClose={onClose}
                printDelivery={(displayPrice: boolean) =>
                    window.open(
                        `/delivery-print/print/${delivery._id}?f=${displayPrice}`,
                        '_blank',
                    )
                }
                printBLDelivery={(displayPrice: boolean) =>
                    window.open(
                        `/delivery-bl-print/print/${delivery._id}?f=${displayPrice}`,
                        '_blank',
                    )
                }
            />
            {orderToSign ? (
                <SignDelivery
                    order={orderToSign}
                    remainingOrders={delivery.pendingOrders.length}
                    onExit={(success: boolean) => {
                        if (success) {
                            if (delivery.pendingOrders.length === 1)
                                changeDeliveryStatus(true);
                            switchOrders(
                                orderToSign,
                                delivery.pendingOrders,
                                delivery.deliveredOrders,
                            );
                        }
                        setOrderToSign(null);
                    }}
                    isExternalPage={isExternalPage}
                />
            ) : (
                <div className={`${isExternalPage && 'mb-[100px]'}`}>
                    <div className="p-4 flex items-center gap-4">
                        <BackArrowButton
                            navigateTo={
                                '/delivery' +
                                (isExternalPage
                                    ? '-ext?f=1'
                                    : '/delivery-list?f=1')
                            }
                        />
                        <h4 className="text-[28px] text-toola-500 font-bold">
                            {delivery && delivery.name}
                        </h4>
                    </div>
                    {!isExternalPage && (
                        <DesktopRecap
                            delivery={delivery}
                            displayPrint={!isExternalPage}
                            onClick={onOpen}
                        />
                    )}
                    <div className="mt-2 flex w-full gap-[2px] overflow-hidden border-b border-gray-200 justify-center flex-row">
                        <div
                            className={`w-[140px]
                    ${
                        toggleState === 'pending'
                            ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                            : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                    }
                    `}
                            onClick={() => {
                                changeToogleState('pending');
                            }}
                        >
                            <p className="font-medium text-navy-700 dark:text-white text-[18px]">
                                <span className="hidden sm:contents">
                                    Livraisons en attente
                                </span>
                                <span className="contents sm:hidden">
                                    En attente
                                </span>{' '}
                                (
                                {delivery ? delivery.pendingOrders.length : '-'}
                                )
                            </p>
                        </div>

                        <div
                            className={`w-[140px]
                        ${
                            toggleState === 'delivered'
                                ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                        }
                    `}
                            onClick={() => {
                                changeToogleState('delivered');
                            }}
                        >
                            <p className="font-medium text-navy-700 dark:text-white text-[18px]">
                                <span className="hidden sm:contents">
                                    Livraisons effectuées
                                </span>
                                <span className="contents sm:hidden">
                                    Effectuées
                                </span>{' '}
                                (
                                {delivery
                                    ? delivery.deliveredOrders.length
                                    : '-'}
                                )
                            </p>
                        </div>
                    </div>
                    {delivery &&
                        toggleState === 'pending' &&
                        (delivery.pendingOrders.length > 0 ? (
                            delivery.pendingOrders
                                .sort(
                                    (a: any, b: any) =>
                                        a.deliveryOrder - b.deliveryOrder,
                                )
                                .map((order: any, index: number) => {
                                    return (
                                        <OrderCard
                                            index={index}
                                            total={
                                                delivery.pendingOrders.length
                                            }
                                            deliveryStarted={
                                                new Date(delivery.startedAt) <=
                                                new Date()
                                            }
                                            order={order}
                                            isExternalPage={isExternalPage}
                                            onChangeOrderStatus={
                                                updateOrderStatus
                                            }
                                            onSign={(order: any) =>
                                                setOrderToSign(order)
                                            }
                                            handleUp={(order: any) => {
                                                moveCard(order, -1);
                                            }}
                                            handleDown={(order: any) => {
                                                moveCard(order, 1);
                                            }}
                                        />
                                    );
                                })
                        ) : (
                            <div className="m-[10px] rounded-xl border bg-white/0 outline-none">
                                <p className="my-6 text-center">
                                    Toutes les commandes ont étées livrées.
                                    <br />
                                    Vous pouvez cliquer sur "Terminer la
                                    tournée".
                                </p>
                                <button
                                    className={`flex w-[200px] h-[40px] mb-3 mx-auto align-center items-center justify-center rounded-xl py-2 text-center text-sm font-medium text-white transition duration-200 leading-none bg-toola-500 hover:bg-toola-600 active:bg-toola-700"`}
                                    onClick={() => {
                                        delivery.pendingOrders.length === 0 &&
                                            changeDeliveryStatus(true);
                                    }}
                                >
                                    Terminer la tournée
                                </button>
                            </div>
                        ))}
                    {delivery &&
                        toggleState === 'delivered' &&
                        (delivery.deliveredOrders.length > 0 ? (
                            delivery.deliveredOrders.map((order: any) => {
                                return (
                                    <OrderCard
                                        deliveryStarted={
                                            new Date(delivery.startedAt) <=
                                            new Date()
                                        }
                                        isExternalPage={isExternalPage}
                                        order={order}
                                        onChangeOrderStatus={updateOrderStatus}
                                        onSign={null}
                                    />
                                );
                            })
                        ) : (
                            <div className="m-[10px] rounded-xl border bg-white/0 outline-none">
                                <p className="my-6 text-center">
                                    Vous n'avez pas encore livré de commandes.
                                </p>
                            </div>
                        ))}
                    <div className="sm:hidden fixed bottom-0 left-0 h-[86px] w-full bg-white border-t border-[#7090B0/0.25]">
                        <div className="m-[10px] px-[16px] py-[11px] h-[66px] border border-[#7090B0/0.25] rounded-[30px] flex justify-between items-center">
                            <div
                                className="flex gap-[10px] items-center"
                                onClick={() => {
                                    setShowTurnSummary(!showTurnSummary);
                                }}
                            >
                                <div className="h-[30px] w-[30px] border border-[#7090B0/0.25] rounded-full flex items-center justify-center">
                                    {!showTurnSummary ? (
                                        <FaChevronUp />
                                    ) : (
                                        <FaChevronDown />
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-600">
                                        {delivery &&
                                            delivery.pendingOrders.length +
                                                delivery.deliveredOrders
                                                    .length +
                                                ' client' +
                                                (delivery.pendingOrders.length +
                                                    delivery.deliveredOrders
                                                        .length !==
                                                1
                                                    ? 's'
                                                    : '')}{' '}
                                        à livrer
                                        <br />
                                        {delivery &&
                                            delivery.pendingOrders.length +
                                                ' client' +
                                                (delivery.pendingOrders
                                                    .length !== 1
                                                    ? 's'
                                                    : '')}{' '}
                                        restant à livrer
                                    </p>
                                </div>
                            </div>
                            {delivery &&
                                (new Date(delivery.startedAt) > new Date() ||
                                delivery.startedAt === undefined ? (
                                    <button
                                        className={`flex w-[130px] h-[34px] items-center justify-center rounded-xl py-2 text-center text-sm font-medium text-white transition duration-200 leading-none ${
                                            delivery.status === 'pending'
                                                ? 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                                : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                                        }`}
                                        onClick={() => {
                                            delivery.status === 'pending' &&
                                                changeDeliveryStatus();
                                        }}
                                    >
                                        Commencer
                                        <br />
                                        la tournée
                                    </button>
                                ) : (
                                    <button
                                        className={`flex w-[130px] h-[34px] items-center justify-center rounded-xl py-2 text-center text-sm font-medium text-white transition duration-200 leading-none ${
                                            delivery.pendingOrders.length === 0
                                                ? 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                                : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                                        }`}
                                        onClick={() => {
                                            delivery.pendingOrders.length ===
                                                0 && changeDeliveryStatus(true);
                                        }}
                                    >
                                        Terminer
                                        <br />
                                        la tournée
                                    </button>
                                ))}
                        </div>
                    </div>
                    {delivery && (
                        <div>
                            <div
                                className={`fixed ${
                                    showTurnSummary ? 'block' : 'hidden'
                                } left-0 top-0 w-full h-[calc(100%-86px)] bg-[#000000] bg-opacity-20`}
                            ></div>
                            <div
                                className={`${
                                    showTurnSummary ? 'flex' : 'hidden'
                                } flex-col items-center fixed bottom-[100px] left-0 h-[calc(84px+${
                                    delivery.items.length * 50
                                }px)] w-[calc(100%-20px)] p-[10px] mx-[10px] bg-white shadow-[0_0_4px_3px_rgba(163,174,288,0.25)] rounded-xl`}
                            >
                                <p className="mt-[5px] text-xl text-[#1B234B] font-medium h-[24px]">
                                    Récapitulatif de la tournée
                                </p>
                                {delivery.items.length > 0 ? (
                                    <div className="w-full px-[20px] max-h-[400px] overflow-auto">
                                        <p className="mt-[20px] text-sm text-[#A3AED0] dark:text-white">
                                            Total des produits de la tournée :
                                        </p>
                                        <table className="mt-[16px] w-full max-h-[400px]">
                                            <thead>
                                                <tr>
                                                    <th className="mt-[20px] w-[60%] px-[10px] text-start text-sm">
                                                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                                                            Produit
                                                        </p>
                                                    </th>
                                                    <th className="mt-[20px] w-[40%] px-[10px] text-start text-sm">
                                                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                                                            Quantité
                                                        </p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {delivery.items.map(
                                                    (product: any) => {
                                                        return (
                                                            <tr className="border-t border-gray-200 h-[30px]">
                                                                <td className="px-[10px]">
                                                                    <p className="font-medium text-sm">
                                                                        {
                                                                            product.name
                                                                        }
                                                                    </p>
                                                                </td>
                                                                <td className="px-[10px]">
                                                                    <p className="font-medium text-sm">
                                                                        {
                                                                            product.quantity
                                                                        }{' '}
                                                                        {
                                                                            product.unity
                                                                        }
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        );
                                                    },
                                                )}
                                            </tbody>
                                        </table>
                                        <p className="mt-[6px] mb-[5px] text-sm text-[#A3AED0] dark:text-white">
                                            Total : {delivery.items.length}{' '}
                                            produit{delivery.items !== 0 && 's'}
                                        </p>
                                    </div>
                                ) : (
                                    <p className="mt-[12px] mb-[8px] text-sm text-gray-600">
                                        Aucun produit
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default EditDeliveries;
