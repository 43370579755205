import { DateRange } from 'react-date-range';
import { useRef, useState } from 'react';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { fr } from 'date-fns/locale';
import { MdCalendarToday } from 'react-icons/md';

const DateRangePicker = (props: {
    defaultStartDate: any;
    defaultEndDate: any;
    onChange?: any;
    label?: string;
}) => {
    const {
        defaultStartDate,
        defaultEndDate,
        onChange,
        label = 'Dates*',
    } = props;

    const [displayDateRange, setDisplayDateRange] = useState(false);
    const ref = useRef(null);
    const [state, setState] = useState([
        {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            key: 'selection',
        },
    ]);

    return (
        <div className="block">
            <label className={`mb-2 text-xs font-medium text-navy-700`}>
                {label}
            </label>
            <div
                className="flex justify-center cursor-pointer border w-[200px] h-[35px] p-1 rounded-xl"
                onClick={() => setDisplayDateRange(!displayDateRange)}
            >
                <p className="flex my-auto text-sm">
                    {state[0].startDate.toLocaleDateString()} {`-`}{' '}
                    {state[0].endDate.toLocaleDateString()}
                </p>
                <MdCalendarToday
                    size={15}
                    className=" flex my-auto text-xl ml-2"
                />
            </div>
            {displayDateRange && (
                <div className="mt-[1px] absolute z-10">
                    <DateRange
                        editableDateInputs={true}
                        onChange={(item: any) => {
                            console.log(item);
                            setState([item.selection]);
                            onChange(item.selection);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        locale={fr}
                        showMonthAndYearPickers={true}
                        className="border rounded-md"
                    />
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;
