import { Routes, Route } from 'react-router-dom';

import RTLLayout from 'layouts/rtl';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import 'assets/css/Plugins.css';
import Access from 'layouts/access';
import Scanner from 'views/stock/scanner';
import Hotjar from '@hotjar/browser';
import CommandPage from 'views/admin/main/ecommerce/commandPage';
import OrderSuccess from 'views/admin/main/ecommerce/commandPage/components/OrderSuccess';
import UiKit from 'views/ui-kit';
import ScrollToTop from 'components/router/scrollToTop';
import Delivery from 'views/external/delivery';
import ListDeliveries from 'views/delivery/listDeliveries';
import EditDeliveries from 'views/delivery/editDeliveries';
import AutoOrderAccount from 'views/external/auto-orders/account';
import PrintDelivery from 'views/delivery/editDeliveries/printDelivery';
import CommingSoon from 'views/comming-soon';
import PrintBLDelivery from 'views/delivery/printDeliveryBL/printBLDelivery';
import MultiPortal from 'views/admin/main/ecommerce/commandPage/MultiPortal';

Hotjar.init(3583940, 6);
const isMaintenance = false;

const App = () => {
    return (
        <div>
            {isMaintenance && <CommingSoon />}
            {!isMaintenance && <ScrollToTop />}
            {!isMaintenance && (
                <Routes>
                    {/* Redirect all trafic to maintenance page */}
                    {/* Internal application */}
                    <Route path="auth/*" element={<AuthLayout />} />
                    <Route path="admin/*" element={<AdminLayout />} />
                    <Route path="/*" element={<AdminLayout />} />
                    <Route path="rtl/*" element={<RTLLayout />} />
                    <Route path="/" element={<Access />} />

                    {/* Ordering pages */}
                    <Route path="/command" element={<CommandPage />} />
                    <Route
                        path="/command/account"
                        element={<AutoOrderAccount />}
                    />
                    <Route path="/command/success" element={<OrderSuccess />} />
                    <Route path="/command/:v" element={<CommandPage />} />
                    <Route path="/portal/:v" element={<MultiPortal />} />
                    <Route path="/portal/" element={<MultiPortal />} />

                    {/* Delivery pages */}
                    <Route path="/delivery-ext/login" element={<Delivery />} />
                    <Route
                        path="/delivery-ext/login/:id"
                        element={<Delivery />}
                    />
                    <Route
                        path="/delivery-ext"
                        element={<ListDeliveries isExternalPage={true} />}
                    />
                    <Route
                        path="/delivery-ext/:date/:deliveryId"
                        element={<EditDeliveries isExternalPage={true} />}
                    />
                    <Route
                        path="/delivery-print/:date/:deliveryId"
                        element={<PrintDelivery isExternalPage={false} />}
                    />

                    {/* Close to delivery-print, but using BL design and data */}
                    <Route
                        path="/delivery-bl-print/:date/:deliveryId"
                        element={<PrintBLDelivery isExternalPage={false} />}
                    />

                    {/* Prototype */}
                    <Route path="/scanner" element={<Scanner />} />

                    {/* Dev */}
                    <Route path="ui-kit/" element={<UiKit />} />
                </Routes>
            )}
        </div>
    );
};

export default App;
