import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import {
    archiveCustomer,
    getCustomer,
    updateCustomer,
} from 'interfaces/customer';
import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
    isFormErrored,
    isValidEmail,
    isValidPhoneNumber,
    isValidSiren,
    isValidVATNumber,
} from 'utils/verification';
import SaveStatus from 'components/actions/SaveStatus';
import env from 'variables/config';
import axios from 'axios';
import InputTagField from 'components/fields/InputTagField';
import { updateMyInfo } from 'interfaces/user';
import DeleteModal from 'components/actions/Delete';
import { useDisclosure } from '@chakra-ui/hooks';

const EditInformationPU = (props: { id?: string; onPageChange: any }) => {
    const { id, onPageChange } = props;
    const navigate = useNavigate();
    const params = useParams();
    const [customer, setCustomer] = useState<any>(null);
    const [saveStatus, setSaveStatus] = useState<any>('none');
    const [user, setUser] = useState(null);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const initUser = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${env.apiUrl}/user/me`, config)
            .then((res: any) => {
                const user = res.data;
                setUser(user);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const addNewTag = async (newTag: string) => {
        if (!user) return;
        await updateMyInfo({ tags: [...user.tags, newTag] });
    };

    const handleChange = (e: any) => {
        setSaveStatus('saving');
        setCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const initCustomer = async (id: string) => {
        const res = await getCustomer(id);
        if (res) {
            setCustomer(res);
            return;
        }
        navigate('/customers');
    };

    const editCustomer = async () => {
        const validationRules: any = {
            mail: (value: any) => !value || isValidEmail(value),
            phone: (value: any) => !value || isValidPhoneNumber(value),
            vatNumber: (value: any) => !value || isValidVATNumber(value),
            siren: (value: any) => !value || isValidSiren(value),
            company: (value: any) => !!value.trim(),
        };

        if (isFormErrored(customer, validationRules)) {
            setSaveStatus('error');
            return;
        }

        const data = {
            _id: customer._id,
            deliveryTourId: customer.deliveryTourId,
            deliveryAddress: customer.deliveryAddress,
            billingAddress: customer.billingAddress,
            billingEmail: customer.billingEmail,
            billingPeriod: customer.billingPeriod,
            contact: customer.contact,
            company: customer.company,
            customerId: customer.customerId,
            mail: customer.mail,
            notes: customer.notes,
            vatNumber: customer.vatNumber,
            siren: customer.siren,
            phone: customer.phone,
            paymentMethod: customer.paymentMethod,
            displayName: customer.displayName,
            tags: customer.tags,
            status: customer.status,
        };
        const res = await updateCustomer(data);
        if (res.data) setSaveStatus('saved');
        else setSaveStatus('error');
    };

    const GoBack = () => {
        return (
            <div
                className="flex items-center justify-start cursor-pointer"
                onClick={() => onPageChange('page')}
            >
                <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
                <p className="text-xs">Retour</p>
            </div>
        );
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            editCustomer();
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initCustomer(params.id || id);
        initUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="absolute top-3 left-2">
                <GoBack />
            </div>
            <DeleteModal
                isOpen={isOpen}
                onClose={onClose}
                description="Êtes-vous sûr de vouloir archiver ce client ?"
                context="un client"
                archived={true}
                deleteFunction={() => {
                    archiveCustomer(params.id);
                    navigate('/customers');
                }}
            />
            <div className="mt-2 grid grid-cols-12 gap-3">
                <div className={`col-span-12`}>
                    {/* Header */}
                    <div className="w-full px-[8px]">
                        <div className="grid grid-cols-4 gap-2">
                            <h4 className="col-span-1 sm:col-span-2 text-lg font-medium text-navy-700 dark:text-white">
                                {customer ? customer.company : 'Edition client'}
                            </h4>
                            <div className="col-span-2 col-start-10">
                                <button
                                    className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-2 py-2 text-xs text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                    onClick={() => onOpen()}
                                >
                                    Archiver{' '}
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* inputs */}
                    <div className="grid gap-3 grid-cols-12">
                        <div className="mt-2 flex col-span-12 justify-between">
                            <p className="text-base">Informations client</p>
                            <SaveStatus status={saveStatus} />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label="Numéro client"
                                placeholder="00012"
                                value={customer?.customerId}
                                id="customerId"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-5">
                            <InputField
                                label="Nom commercial"
                                placeholder="Nom commercial"
                                value={
                                    customer?.displayName
                                        ? customer?.displayName
                                        : ''
                                }
                                id="displayName"
                                type="text"
                                onChange={handleChange}
                                isLoaded={customer}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-4">
                            <InputField
                                label="Raison sociale*"
                                placeholder="Toola"
                                value={customer?.company}
                                id="company"
                                type="text"
                                onChange={handleChange}
                                error="Raison sociale invalide"
                                required={true}
                                isLoaded={customer}
                                inputSmall={true}
                            />
                        </div>
                        {/* <div className="col-span-12 lg:col-span-3">
                            <SearchSelector
                                label="Statut du prospect*"
                                displayKey="name"
                                value={customer?.status}
                                inputSmall={true}
                                options={[
                                    {
                                        _id: "qualified",
                                        name: "Prise de contact",
                                    },
                                    {
                                        _id: "meeting",
                                        name: "Réunion planifié",
                                    },
                                    {
                                        _id: "proposal",
                                        name: "Relance",
                                    },
                                    {
                                        _id: "active",
                                        name: "Deal gagné",
                                    },
                                ]}
                                onSelect={(e: any) => {
                                    setSaveStatus("saving");
                                    setCustomer((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        status: e._id,
                                    }));
                                }}
                            />
                        </div> */}
                        <div className="col-span-12 lg:col-span-4">
                            <InputField
                                label="Email"
                                placeholder="mathieu@toola.eu"
                                error={'Invalide'}
                                isInvalid={!isValidEmail(customer?.mail)}
                                value={customer?.mail ? customer?.mail : ''}
                                id="mail"
                                type="email"
                                onChange={handleChange}
                                isLoaded={customer}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label="Téléphone"
                                placeholder="Téléphone"
                                error={'invalide'}
                                isInvalid={!isValidPhoneNumber(customer?.phone)}
                                value={customer?.phone}
                                id="phone"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label={`Contact`}
                                placeholder="Contact"
                                value={customer?.contact}
                                id="contact"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-4 grid">
                            <InputField
                                label={`Notes utiles`}
                                placeholder="Notes utiles"
                                value={customer?.notes}
                                id="notes"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="z-50 col-span-12 lg:col-span-6">
                            <InputTagField
                                label="Tags clients"
                                placeholder=""
                                id="tags"
                                type="text"
                                extra=""
                                tags={customer?.tags}
                                allTags={
                                    user
                                        ? user.tags.map((name: string) => ({
                                              name,
                                          }))
                                        : []
                                }
                                onTagCreate={(tag: any) => addNewTag(tag)}
                                onChange={(tags: any) =>
                                    handleChange({
                                        target: { id: 'tags', value: tags },
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="mt-8 grid gap-3 grid-cols-12">
                        <p className="col-span-12 text-base">
                            Informations de facturation
                        </p>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label="Email de facturation"
                                placeholder="mathieu@toola.eu"
                                error={'Email invalide'}
                                isInvalid={
                                    !isValidEmail(customer?.billingEmail)
                                }
                                value={
                                    customer?.billingEmail
                                        ? customer?.billingEmail
                                        : ''
                                }
                                id="billingEmail"
                                type="email"
                                onChange={handleChange}
                                isLoaded={customer}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <SearchSelector
                                label="Période de facturation"
                                displayKey="name"
                                value={customer?.billingPeriod}
                                inputSmall={true}
                                options={[
                                    {
                                        _id: 'flexible',
                                        name: 'flexible',
                                    },
                                    { _id: 'delivery', name: 'à la livraison' },
                                    {
                                        _id: '10-days',
                                        name: '10 jours',
                                    },
                                    {
                                        _id: '15-days',
                                        name: '15 jours',
                                    },
                                    { _id: '1-month', name: '1 mois' },
                                ]}
                                onSelect={(e: any) => {
                                    setSaveStatus('saving');
                                    setCustomer((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        billingPeriod: e._id,
                                    }));
                                }}
                            />
                        </div>
                        <div className="mt-4 col-span-12 lg:col-span-6 lg:mt-0">
                            <InputField
                                label={`Adresse de facturation`}
                                placeholder="Champ de Mars, 5 Av. Anatole France, 75007 Paris, France"
                                id="billingAddress"
                                type="text"
                                value={
                                    customer?.billingAddress
                                        ? customer?.billingAddress
                                        : ''
                                }
                                onChange={handleChange}
                                isLoaded={customer}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <SearchSelector
                                label="Moyen de paiement*"
                                displayKey="name"
                                value={customer?.paymentMethod}
                                inputSmall={true}
                                options={[
                                    {
                                        _id: 'cash',
                                        name: 'Espèces',
                                    },
                                    {
                                        _id: 'check',
                                        name: 'Chèque',
                                    },
                                    {
                                        _id: 'transfer',
                                        name: 'Virement',
                                    },
                                    {
                                        _id: 'credit-card',
                                        name: 'Carte bancaire',
                                    },
                                    {
                                        _id: 'direct-debit',
                                        name: 'Prélèvement',
                                    },
                                ].filter(
                                    (e) =>
                                        !user ||
                                        user?.acceptedPaymentMethods?.includes(
                                            e._id,
                                        ),
                                )}
                                onSelect={(e: any) => {
                                    setSaveStatus('saving');
                                    setCustomer((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        paymentMethod: e._id,
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label={`Numéro de TVA`}
                                placeholder="FR32123456789"
                                value={customer?.vatNumber}
                                id="vatNumber"
                                type="text"
                                onChange={handleChange}
                                isInvalid={
                                    !isValidVATNumber(customer?.vatNumber)
                                }
                                error={'Numéro de TVA invalide'}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label={`Numéro de SIREN`}
                                placeholder="123568941"
                                value={customer?.siren}
                                id="siren"
                                type="text"
                                onChange={handleChange}
                                isInvalid={!isValidSiren(customer?.siren)}
                                error={'SIREN invalide'}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditInformationPU;
